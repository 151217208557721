// ** React Import
import { useState } from "react";

// ** MUI Imports
import {
  DataGrid,
  GridColDef,
  GridRowId,
  GridSortItem,
} from "@mui/x-data-grid";

interface Props {
  columns: GridColDef[];
  sortModel?: GridSortItem[];
  rows: any;
  onDetail?: any;
  rowCountState: number;
  paginationModel: any;
  onPaginationModelChange: any;
}

export default function DataGridSpeacial(props: Props) {
  const {
    columns,
    sortModel,
    rows,
    onDetail,
    rowCountState,
    paginationModel,
    onPaginationModelChange,
  } = props;

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowId[]>([]);

  return (
    <div style={{ width: "100%" }}>
      <DataGrid
        slotProps={{
          pagination: {
            labelRowsPerPage: 'Sayfa Başına Satır Sayısı',
          },
        }}
        localeText={{
          noRowsLabel: 'Veri Bulunamadı',
          noResultsOverlayLabel: 'Veri Bulunamadı'
        }}
        autoHeight
        pageSizeOptions={[10, 25, 50, 100]}
        paginationMode="server"
        rowCount={rowCountState}
        rows={rows}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        disableRowSelectionOnClick
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setRowSelectionModel(newRowSelectionModel);
        }}
        rowSelectionModel={rowSelectionModel}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
          sorting: {
            sortModel: sortModel,
          },
        }}
        columns={columns}
        onRowDoubleClick={(event) => {
          onDetail(event.row.id);
        }}
      />
    </div>
  );
}
