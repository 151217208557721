// ** Icons Imports
import {
  CloseRounded,
  DoneRounded,
  FlagTwoTone,
  PersonRounded,
} from "@mui/icons-material";
import MuiToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { MouseEvent, useState } from "react";
import { ShiftInfoChange } from "../pages/InputsPage";
import axios from 'axios';

// ** Types
type BreakChangeDialogProps = {
  accessToken: string;
  shiftInfo: ShiftInfoChange;
  setShiftInfo: (value: React.SetStateAction<ShiftInfoChange>) => void;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

// Styled ToggleButton component
const ToggleButton = styled(MuiToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    margin: theme.spacing(5),
    border: "none !important",
    textTransform: "capitalize",
    color: "#f0f3f4",
    "&.Mui-selected, &.Mui-selected:hover": {
      backdropFilter: "blur(16px)",
      color: "#212121",
      backgroundColor: "rgba(255, 255, 255, 0.21)",
    },
    "&:not(:first-of-type)": {
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
    "&:first-of-type": {
      borderRadius: `${theme.shape.borderRadius}px !important`,
    },
  })
);

const API_URL = process.env.REACT_APP_API_HOST || "";

const BreakChangeDialog = (props: BreakChangeDialogProps) => {

  // ** Props
  const { shiftInfo, setShiftInfo, dialogState, setDialogState, accessToken } = props;

  // ** States
  const [value, setValue] = useState<String | null>(null);

  const handleOperatorChange = (
    event: MouseEvent<HTMLElement>,
    pValue: string | null
  ) => {
    setValue(pValue);
    setShiftInfo({...shiftInfo, operatorFullName: pValue})
  };

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  // **
  const onSaveShiftInfo = () => {
    if(shiftInfo.value) {
      axios.post(API_URL + '/setStartLine', {
        val: false,
        operator_name: shiftInfo.operatorFullName,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': accessToken
        }
      }).then((result) => {
        if(result.data === false) {
          setShiftInfo({...shiftInfo, value: false})
          setDialogState(false)
        }
      })
    } else {
      axios.post(API_URL + '/setStartLine', {
        val: true,
        operator_name: shiftInfo.operatorFullName,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'x-token': accessToken
        }
      }).then((result) => {
        if(result.data === true) {
          setShiftInfo({...shiftInfo, value: true})
          setDialogState(false)
        }
      })
    }

  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="lg"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            {shiftInfo ? "Vardiya Bitir" : "Vardiya Başlat"}
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5 }}>
        <Box
          sx={{
            width: "100%",
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
            }}
          >
            {shiftInfo
              ? "Vardiyayı bitirmek için onay gerekiyor."
              : "Vardiyayı başlatmak için operatör seçimi ve onay gerekiyor."}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {
          <ToggleButtonGroup
            color="success"
            exclusive
            value={value}
            onChange={handleOperatorChange}
            aria-label="state-button-toggle"
            disabled={shiftInfo.value}
          >
            <ToggleButton value="OĞUZ UYAR" aria-label="high">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <PersonRounded
                  sx={{
                    fontSize: "9rem",
                  }}
                />
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",
                  }}
                >
                  OĞUZ UYAR
                </Typography>
              </Box>
            </ToggleButton>
            <ToggleButton value="SELAMİ VURAL" aria-label="medium">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <PersonRounded
                  sx={{
                    fontSize: "9rem",
                  }}
                />
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",
                  }}
                >
                  SELAMİ VURAL
                </Typography>
              </Box>
            </ToggleButton>
            <ToggleButton value="FATİH ŞAHİN" aria-label="ship">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  flexDirection: "column",
                }}
              >
                <Box sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <PersonRounded
                  sx={{
                    fontSize: "9rem",
                  }}
                />
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.5rem",
                  }}
                >
                  FATİH ŞAHİN
                </Typography>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
          }

        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: '#f0f3f4',
            fontSize: '1.3rem',
            borderColor: 'rgba(255, 255, 255, 0.36)'
          }}
          onClick={() => setDialogState(false)}
        >
          İptal
        </Button>
        <Button
          sx={{
            fontSize: '1.3rem',
            color: '#f0f3f4',
            background: 'rgba(255, 255, 255, 0.36)'
          }}
          variant="contained"
          onClick={onSaveShiftInfo}
          startIcon={<DoneRounded sx={{
            fontSize: '1.3rem'
          }} />}
        >
          Onayla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BreakChangeDialog;
