// ** MUI Imports
import { Box, Typography } from "@mui/material";
import { useSnapshot } from "valtio";
import { debugModeState } from "../global-states/debug-mode";

// ** Types
type DebugModeProps = {
  label?: string;
  position?: string;
  top?: number | string;
  left?: number | string;
};

const DebugModeInfo = (props: DebugModeProps) => {
  const { label = "bilinmiyor", position="relative", top=-15, left= '50%', } = props;

  const debugMode = useSnapshot(debugModeState)

  return (
    <Box
    sx={{
      position,
      left,
      transform: `translateX(-${left})`,
      top,
      display: debugMode.value ? "flex" : "none",
      justifyContent: "center",
      alignItems: "center",
      background: '#d0d3d4',
      px: 1,
      py: 0.5,
      borderRadius: 1,
    }}
  >
    <Typography
      sx={{
        fontWeight: 600,
        fontSize: "0.72rem",
        color: "#2e4053",
      }}
    >
      {label}
    </Typography>
    </Box>
  );
};

export default DebugModeInfo;
