// ** Icons Imports
import {
  CloseRounded,
  SensorsRounded,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import DataGridSpeacial from "./DataGridSpecial";
import { convertMsToTime } from "../App";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import { Machines, Sensor } from "../message";

// ** Types
type MachineInfoDialogProps = {
  machineData: Machines[0];
  machineInfoDialog: boolean;
  setMachineInfoDialog: (value: React.SetStateAction<boolean>) => void;
};

const MachineInfoDialog = (props: MachineInfoDialogProps) => {
  // ** Props
  const { machineData, machineInfoDialog, setMachineInfoDialog } = props;

  // ** Columns
  const customizedColumns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.07,
        minWidth: 172,
        field: "name",
        headerName: "Sensör Adı",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        minWidth: 128,
        field: "count",
        headerName: "Tekrar Sayısı",
        align: "center",
        headerAlign: "center",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.count}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        minWidth: 128,
        field: "time",
        headerName: "Duruş Süresi",
        align: "right",
        headerAlign: "right",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, mr:1, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.time ? convertMsToTime(row.time) : '-'}
            </Typography>
          );
        },
      },
    ],
    []
  );

  // ** States
  const [rows, setRows] = useState<Sensor[]>([]);
  const [rowCountState, setRowCountState] = useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // ** Functions
  const onClose = () => {
    setMachineInfoDialog(false);
  };

  useEffect(() => {
    setRows(machineData.Sensors);
    setRowCountState(machineData.Sensors.length);
  }, [machineData]);

  return (
    <Dialog
      fullWidth
      open={machineInfoDialog}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <SensorsRounded
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            Makine Bilgileri
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5 }}>
        <Box
          sx={{
            width: "100%",
            px: 2,
            pb: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              px: 1.35,
              py: 0.45,
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",              borderRadius: 12,
            }}
          >
            <Typography
              noWrap
              align="center"
              sx={{ fontSize: 13, color: "#ECF0F1", fontWeight: 600 }}
            >
              {machineData.name} Makinesi
            </Typography>
          </Box>
        </Box>
        <DataGridSpeacial
          columns={customizedColumns}
          rows={rows}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </DialogContent>
    </Dialog>
  );
};

export default MachineInfoDialog;
