// ** MUI Imports
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { convertMsToTime } from "../App";
import React, { useEffect, useState } from "react";
import { SearchRounded } from "@mui/icons-material";

type RegisterType = {
  name: string;
  value: number;
  time: number;
};

const RegisterDetailPage = () => {
  // ** Props

  // ** States
  const [init, setInit] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [rows, setRows] = useState<RegisterType[]>([]);
  const [filteredRows, setFilteredRows] = useState<RegisterType[]>([]);

  useEffect(() => {
    if(searchText === '' && init) {
      setFilteredRows(rows)
    } else {
      const filtered = rows.filter(
        (item) => item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
      );
      setFilteredRows(filtered);
    }
  }, [searchText, init, rows]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100dvh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TextField
          id="input-with-icon-textfield"
          value={searchText}
          sx={{
            borderRadius: 1,
            borderColor: "rgb(236, 240, 241)",
            bgcolor: "rgba(236, 240, 241, 0.9)",
            my: 1,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSearchText(event.target.value);
          }}
          variant="outlined"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          alignItems: "flex-start",
          flexWrap: "wrap",
          height: "calc(100dvh - 80px)",
          overflow: "auto",
          paddingTop: 1,
          paddingBottom: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "left",
            alignItems: "flex-start",
            flexWrap: "wrap",
          }}
        >
          {filteredRows.map((item, index) => (
            <div
              key={index}
              className="card"
              style={{
                paddingTop: 8,
                paddingRight: 14,
                paddingBottom: 8,
                paddingLeft: 14,
                margin: 11,
                borderLeft: "1px solid rgb(236, 240, 241)",
                borderBottom: "1px solid rgb(236, 240, 241)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  minWidth: 136,
                  minHeight: 52,
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: 42,
                    p: 0,
                    height: 42,
                    mr: 1.5,
                    display: "flex",
                    borderRadius: 3,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(236, 240, 241, 0.21)",
                    color: "rgb(236, 240, 241)",
                    border: "1px solid rgb(236, 240, 241)",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: "1.1rem",
                      lineHeight: "1.1rem",
                      color: "rgb(236, 240, 241)",
                    }}
                  >
                    {index + 1}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "0.85rem",
                      lineHeight: "0.85rem",
                      color: "#CACFD2",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      mt: 1,
                      fontWeight: 700,
                      fontSize: "1.2rem",
                      lineHeight: "1.2rem",
                      color: "#FBFCFC",
                    }}
                  >
                    {item.name.indexOf("Time") !== -1
                      ? convertMsToTime(item.value)
                      : item.value}
                  </Typography>
                </Box>
              </Box>
            </div>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default RegisterDetailPage;
