// ** Icons Imports
import {
  MoodRounded,
  SentimentDissatisfiedRounded,
  SentimentNeutralRounded,
  SentimentSatisfiedAltRounded,
} from "@mui/icons-material";

// ** MUI Imports
import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";

// ** Types
type ProductivityForMachineProps = {
  productivityValue?: number;
};

const ProductivityForMachine = (props: ProductivityForMachineProps) => {
  const { productivityValue = 0 } = props;

  const [productivityColor, setProductivityColor] =
    useState<string>("2, 224, 164");
  const [productivityEmoji, setProductivityEmoji] = useState<any>();

  const returnColor = () => {
    if (productivityValue >= 85) {
      return "2, 224, 164";
    }

    if (productivityValue >= 60) {
      return "131, 217, 20";
    }

    if (productivityValue >= 40) {
      return "243, 156, 18";
    }

    return "255, 76, 81";
  };

  useEffect(() => {
    setProductivityColor(returnColor());
    setProductivityEmoji(returnEmoji());
  }, [productivityValue]);

  const returnEmoji = () => {
    if (productivityValue >= 85) {
      return (
        <MoodRounded
          sx={{
            fontSize: 24,
          }}
        />
      );
    }

    if (productivityValue >= 60) {
      return <SentimentSatisfiedAltRounded sx={{ fontSize: 24 }} />;
    }

    if (productivityValue >= 40) {
      return (
        <SentimentNeutralRounded
          sx={{
            fontSize: 24,
          }}
        />
      );
    }

    return (
      <SentimentDissatisfiedRounded
        sx={{
          fontSize: 24,
        }}
      />
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        px: 1,
        gap: 1,
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: "0.75rem",
          color: `rgb(${productivityColor})`,
        }}
      >
        {productivityValue && productivityValue.toFixed(1)} % OEE
      </Typography>
      <Box
        sx={{
          width: 24,
          p: 0,
          height: 24,
          display: "flex",
          borderRadius: "50%",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: `rgba(${productivityColor}, 0.21)`,
          color: `rgb(${productivityColor})`,
        }}
      >
        {productivityEmoji}
      </Box>
    </Box>
  );
};

export default ProductivityForMachine;
