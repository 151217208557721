import React, { useEffect, useState } from "react";
import logo from "./../logo-white.png";
import type {} from "@mui/x-data-grid/themeAugmentation";

import {
  AppBar,
  Box,
  Container,
  Grow,
  styled,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  PersonRounded,
  PlayCircleTwoTone,
  StopCircleTwoTone,
} from "@mui/icons-material";
import Moment from "react-moment";
import { i18n } from "dateformat";
import useWebSocket from "react-use-websocket";
import { Message } from "../message";
import BreakChangeDialog from "../components/BreakChangeDialog";
import ShiftChangeDialog from "../components/ShiftChangeDialog";
import { useParams } from "react-router-dom";
import CleaningAndMaintenanceChangeDialog from "../components/CleaningAndMaintenanceChangeDialog";
import ReactCustomSwitch from "../components/slider-switch/SliderSwitch";
import ErrorChangeDialog from "../components/ErrorChangeDialog";

export type ErrorInfoChange = {
  title: string;
  isStarted: boolean;
  machine: any;
  duration: number;
  description?: string;
};

export type ShiftInfoChange = {
  value: boolean;
  operatorFullName: string | null;
};

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertMsToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

const CssTextField = styled(TextField)({
  "& label.Mui-focused": {
    color: "#fff",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#fff",
  },
  "& .MuiOutlinedInput-root": {
    color: "#f0f3f4",
    "& fieldset": {
      borderWidth: "1px",
      borderColor: "rgba(255, 255, 255, 0.24)",
    },
    "&:hover fieldset": {
      borderColor: "rgba(255, 255, 255, 0.48)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "rgba(255, 255, 255, 0.52)",
    },
  },
});

function InputsPage() {
  // Settings
  i18n.dayNames = [
    "Paz",
    "Pzt",
    "Sal",
    "Çar",
    "Per",
    "Cum",
    "Cmt",
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  // ** States

  const [processTime, setProcessTime] = useState(0);

  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0,
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      WarningTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      UnplannedStops: 0,
      UnplannedStopsDetail: [],
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  let { accessToken } = useParams();

  const [testWS, settestWS] = useState<boolean>(false);

  const [timeString, setTimeString] = useState<Date>(new Date());

  const [shiftChangeDialogState, setShiftChangeDialogState] =
    useState<boolean>(false);

  const [
    cleaningAndMaintenanceChangeDialogState,
    setCleaningAndMaintenanceChangeDialogState,
  ] = useState<boolean>(false);

  const [breakChangeDialogState, setBreakChangeDialogState] =
    useState<boolean>(false);

  const [errorChangeDialogState, setErrorChangeDialogState] =
    useState<boolean>(false);

  const [shiftInfo, setShiftInfo] = useState<ShiftInfoChange>({
    value: false,
    operatorFullName: null,
  });
  const [cleaningAndMaintenanceInfo, setCleaningAndMaintenanceInfo] =
    useState<boolean>(false);

  const [breakInfo, setBreakInfo] = useState<boolean>(false);

  const [errorInfo, setErrorInfo] = useState<ErrorInfoChange>({
    title: "",
    isStarted: false,
    machine: "",
    duration: 0,
    description: undefined,
  });

  useEffect(() => {
    if (errorInfo.isStarted) {
      //Implementing the setInterval method
      const intervalInfo = setInterval(() => {
        setErrorInfo({ ...errorInfo, duration: errorInfo.duration + 1 });
      }, 1000);

      //Clearing the interval
      return () => clearInterval(intervalInfo);
    }
  }, [errorInfo]);

  const handleShiftChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setShiftChangeDialogState(true);
  };

  const handleCleaningAndMaintenanceInfoChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setCleaningAndMaintenanceChangeDialogState(true);
  };

  const handleBreakChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setBreakChangeDialogState(true);
  };

  const handleErrorChange = (
    checked: boolean,
    event: React.SyntheticEvent<MouseEvent | KeyboardEvent> | MouseEvent,
    id: string
  ) => {
    setErrorInfo({ ...errorInfo, isStarted: checked });
  };

  // ** Functions

  // ** UseEffects
  useEffect(() => {
    setTimeString(new Date(Number(processTime) * 1000));
  }, [processTime]);

  const WS_URL = process.env.REACT_APP_WEBSOCKET_HOST || "";

  const { lastMessage } = useWebSocket(WS_URL, {
    onOpen: () => true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    try {
      lastMessage && setMessage(JSON.parse(lastMessage.data));
      lastMessage && setProcessTime(JSON.parse(lastMessage.data).Time);
    } catch {}
  }, [lastMessage]);

  useEffect(() => {
    setShiftInfo({
      value: message.Line.StartProduction,
      operatorFullName: message.operator_name ?? null,
    });

  }, [message.Line.StartProduction, message.operator_name]);

  useEffect(() => {
    setCleaningAndMaintenanceInfo(message.Line.StartScheduledStops);

  }, [message.Line.StartScheduledStops]);

  useEffect(() => {
    setBreakInfo(message.Line.StartBreaks);

  }, [message.Line.StartBreaks]);



  return (
    <div
      style={{
        position: "relative",
        display: "block",
      }}
    >
      <AppBar
        position="fixed"
        color="transparent"
        sx={{
          background: "transparent",
          boxShadow: "none",
          backdropFilter: { xs: "blur(10px)", md: "none" },
        }}
      >
        <Toolbar sx={{ flexWrap: "wrap" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: { xs: "center", sm: "space-between" },
              flexWrap: "wrap",
              alignItems: "center",
              alignContent: "center",
              mt: 3.5,
              mb: 2,
              ml: 1,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
                flexDirection: "column",
                backdropFilter: "blur(10px)",
                borderRadius: 3,
                px: 2,
                py: 1,
              }}
            >
              <img
                src={logo}
                style={{
                  height: 36,
                }}
                alt="logo"
              />
              <Typography
                sx={{
                  color: "#FBFCFC !important",
                  fontWeight: 400,
                  fontSize: "0.85rem",
                  pr: 1,
                }}
              >
                Coding For Life
              </Typography>
            </Box>
            <Box>
              <Moment
                style={{
                  fontSize: "1.3rem",
                  fontWeight: 600,
                  color: "#f0f3f4",
                }}
                className="desc"
                format="DD-MM-yyyy HH:mm:ss"
              >
                {Date.now()}
              </Moment>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: 1,
                borderRadius: 2,
                backdropFilter: "blur(16px)",
                flexWrap: "wrap",
                gap: 0.8,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1.5,
                  pl: 0.8,
                  pr: 1.5,
                  py: 0.8,
                  borderRadius: 2,
                  maxHeight: 42,
                  background: "rgba(240, 243, 244, 0.21)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "cneter",
                    alignItems: "center",
                    background: "rgba(240, 243, 244, 0.21)",
                    borderRadius: 1,
                    borderBottom: "2px solid rgba(240, 243, 244, 0.81)",
                  }}
                >
                  <PersonRounded
                    fontSize="small"
                    sx={{
                      m: 0.5,
                      color: "#f0f3f4",
                    }}
                  />
                </Box>

                <Typography
                  variant="subtitle2"
                  sx={{
                    color: "#f0f3f4",
                  }}
                >
                  {message.operator_name ?? "Operatör Seçilmedi"}
                </Typography>
              </Box>
              <Box
                sx={{
                  position: "relative",
                  minWidth: 110,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                  borderRadius: 2,
                  maxHeight: 42,
                  backgroundColor: `rgba(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  }  , 0.21)`,
                  backdropFilter: "blur(10px)",
                  color: `rgb(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  })`,
                  border: `1px solid rgb(${
                    message !== undefined && message.Connected === false
                      ? "255, 76, 81"
                      : "2, 224, 164"
                  })`,
                }}
              >
                <Box mb={1} ml={1}>
                  <span className="heartbeat"></span>
                  <span className="dot"></span>
                </Box>
                <Box>
                  <Moment className="desc" format="HH:mm:ss">
                    {timeString}
                  </Moment>
                </Box>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Container
        disableGutters
        maxWidth={false}
        sx={{
          width: "100dvw !important",
          maxHeight: "100dvh !important",
          overflow: "auto",
          pt: { xs: 28, sm: 24, md: 18 },
          pb: 3,
        }}
      >
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          flexDirection="column"
          flexWrap="wrap"
          alignItems="center"
          sx={{
            gap: 2,
          }}
        >
          <div
            className="card"
            style={{
              width: "85%",
              margin: 5,
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
              borderLeft: "5px solid rgb(2, 224, 164)",
              background: "rgba(2, 224, 164, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                minHeight: 128,
                p: 1,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 1.5,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(2, 224, 164)",
                  }}
                >
                  {shiftInfo.value ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "2.1rem",
                    color: "rgb(2, 224, 164)",
                  }}
                >
                  Vardiya
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 3,
                  alignItems: "center",
                  flexWrap: "wrap",
                  mr: 2.5,
                }}
              >
                <Grow
                  in={shiftInfo.value}
                  style={{
                    transformOrigin: "10 0 0",
                  }}
                  timeout={1000}
                >
                  <Box
                    sx={{
                      display: shiftInfo.value ? "flex" : "none",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      py: 1,
                      px: 2,
                      borderRadius: 2.5,
                      height: 96,
                      background: "rgba(0,0,0,66)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.2rem",
                        color: "#CACFD2",
                      }}
                    >
                      Süre
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 700,
                        fontSize: "1.75rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {convertMsToTime(message.Line.PresentTime)}
                    </Typography>
                  </Box>
                </Grow>
                <ReactCustomSwitch
                  width={216}
                  height={72}
                  onChange={handleShiftChange}
                  checked={shiftInfo.value}
                  disabled={
                    shiftInfo.value && (breakInfo || cleaningAndMaintenanceInfo)
                  }
                />
              </Box>
            </Box>
          </div>
          <div
            className="card"
            style={{
              width: "85%",
              margin: 5,
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
              borderLeft: "5px solid rgb(236, 240, 241)",
              background: "rgba(236, 240, 241, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexWrap: "wrap",
                alignItems: "center",
                minHeight: 128,
                p: 1,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 1.5,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(236, 240, 241)",
                  }}
                >
                  {cleaningAndMaintenanceInfo ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "2.1rem",
                    color: "rgb(236, 240, 241)",
                  }}
                >
                  Temizlik ve Bakım
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 3,
                  alignItems: "center",
                  flexWrap: "wrap",
                  mr: 2.5,
                }}
              >
                <Grow
                  in={cleaningAndMaintenanceInfo}
                  style={{
                    transformOrigin: "10 0 0",
                  }}
                  timeout={1000}
                >
                  <Box
                    sx={{
                      display: cleaningAndMaintenanceInfo ? "flex" : "none",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      py: 1,
                      px: 2,
                      borderRadius: 2.5,
                      height: 96,
                      background: "rgba(0,0,0,66)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.2rem",
                        color: "#CACFD2",
                      }}
                    >
                      Süre
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 700,
                        fontSize: "1.75rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {convertMsToTime(message.Line.PlannedStops1 * 60)}
                    </Typography>
                  </Box>
                </Grow>
                <ReactCustomSwitch
                  width={216}
                  height={72}
                  onChange={handleCleaningAndMaintenanceInfoChange}
                  checked={cleaningAndMaintenanceInfo}
                  disabled={!shiftInfo.value || breakInfo}
                />
              </Box>
            </Box>
          </div>
          <div
            className="card"
            style={{
              width: "85%",
              margin: 5,
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
              borderLeft: "5px solid rgb(243, 156, 18)",
              background: "rgba(243, 156, 18, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                p: 1,
                minHeight: 128,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 1.5,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(243, 156, 18)",
                  }}
                >
                  {breakInfo ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "2.1rem",
                    color: "rgb(243, 156, 18)",
                  }}
                >
                  Mola
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 3,
                  flexWrap: "wrap",
                  alignItems: "center",
                  mr: 2.5,
                }}
              >
                <Grow
                  in={breakInfo}
                  style={{
                    transformOrigin: "10 0 0",
                  }}
                  timeout={1000}
                >
                  <Box
                    sx={{
                      display: breakInfo ? "flex" : "none",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      py: 1,
                      px: 2,
                      borderRadius: 2.5,
                      height: 96,
                      background: "rgba(0,0,0,66)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.2rem",
                        color: "#CACFD2",
                      }}
                    >
                      Süre
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 700,
                        fontSize: "1.75rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {convertMsToTime(message.Line.WarningTime)}
                    </Typography>
                  </Box>
                </Grow>
                <ReactCustomSwitch
                  width={216}
                  height={72}
                  onChange={handleBreakChange}
                  checked={breakInfo}
                  disabled={!shiftInfo.value || cleaningAndMaintenanceInfo}
                />
              </Box>
            </Box>
          </div>
          <div
            className="card"
            style={{
              width: "85%",
              margin: 5,
              padding: 0,
              paddingLeft: 7,
              paddingRight: 7,
              borderLeft: "5px solid rgb(255, 76, 81)",
              background: "rgba(255, 76, 81, 0.21)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                padding: 1,
                minHeight: 128,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 1,
                }}
              >
                <Box
                  sx={{
                    p: 0,
                    mr: 1.5,
                    display: "flex",
                    borderRadius: "50%",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "rgb(255, 76, 81)",
                  }}
                >
                  {errorInfo.isStarted ? (
                    <StopCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  ) : (
                    <PlayCircleTwoTone
                      sx={{
                        fontSize: 96,
                      }}
                    />
                  )}
                </Box>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "2.1rem",
                    color: "rgb(255, 76, 81)",
                  }}
                >
                  Duruşlar
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 3,
                  flexWrap: "wrap",
                  alignItems: "center",
                  mr: 2.5,
                }}
              >
                <Grow
                  in={errorInfo.isStarted}
                  style={{
                    transformOrigin: "10 0 0",
                  }}
                  timeout={1000}
                >
                  <Box
                    sx={{
                      display: errorInfo.isStarted ? "flex" : "none",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center",
                      py: 1,
                      px: 2,
                      borderRadius: 2.5,
                      background: "rgba(0,0,0,66)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.1rem",
                        lineHeight: "1.1rem",
                        color: "#CACFD2",
                      }}
                    >
                      Hata Süresi
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 700,
                        fontSize: "1.2rem",
                        lineHeight: "1.2rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {convertMsToTime(errorInfo.duration)}
                    </Typography>
                  </Box>
                </Grow>
                <ReactCustomSwitch
                  width={216}
                  height={72}
                  onChange={handleErrorChange}
                  checked={errorInfo.isStarted}
                />
              </Box>
            </Box>
          </div>
          <Grow
            in={errorInfo.isStarted}
            style={{
              transformOrigin: "10 0 0",
            }}
            timeout={1000}
          >
            <Box
              sx={{
                display: errorInfo.isStarted ? "block" : "none",
                borderRadius: 2.5,
                minWidth: 300,
                background: "rgba(0,0,0,66)",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.1rem",
                    lineHeight: "1.1rem",
                    color: "#CACFD2",
                  }}
                >
                  A Hatası
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.1rem",
                    lineHeight: "1.1rem",
                    color: "#CACFD2",
                  }}
                >
                  B Hatası
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.1rem",
                    lineHeight: "1.1rem",
                    color: "#CACFD2",
                  }}
                >
                  C Hatası
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  py: 1,
                  px: 2,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: "1.1rem",
                    lineHeight: "1.1rem",
                    color: "#CACFD2",
                  }}
                >
                  Diğer
                </Typography>
                <ReactCustomSwitch
                  onChange={handleErrorChange}
                  checked={errorInfo.isStarted}
                />
              </Box>
              <Box
                sx={{
                  display: "block",
                  padding: 1,
                }}
              >
                <CssTextField
                  placeholder="Açıklama"
                  name="description"
                  variant="outlined"
                  fullWidth
                  multiline
                  sx={{
                    color: "#f3f4f5",
                  }}
                />
              </Box>
            </Box>
          </Grow>
        </Box>
        <ShiftChangeDialog
          accessToken={accessToken as string}
          shiftInfo={shiftInfo}
          setShiftInfo={setShiftInfo}
          dialogState={shiftChangeDialogState}
          setDialogState={setShiftChangeDialogState}
        />
        <CleaningAndMaintenanceChangeDialog
          accessToken={accessToken as string}
          cleaningAndMaintenanceInfo={cleaningAndMaintenanceInfo}
          setCleaningAndMaintenanceInfo={setCleaningAndMaintenanceInfo}
          dialogState={cleaningAndMaintenanceChangeDialogState}
          setDialogState={setCleaningAndMaintenanceChangeDialogState}
        />
        <BreakChangeDialog
          accessToken={accessToken as string}
          breakInfo={breakInfo}
          setBreakInfo={setBreakInfo}
          dialogState={breakChangeDialogState}
          setDialogState={setBreakChangeDialogState}
        />
        <ErrorChangeDialog
          accessToken={accessToken as string}
          errorInfo={errorInfo}
          setErrorInfo={setErrorInfo}
          dialogState={errorChangeDialogState}
          setDialogState={setErrorChangeDialogState}
        />
      </Container>
    </div>
  );
}

export default InputsPage;
