import React, { useEffect, useState } from "react";
import type {} from "@mui/x-data-grid/themeAugmentation";

import { Box, Card, CardContent, Typography } from "@mui/material";

import { Message } from "../message";
import { ArcherContainer, ArcherElement } from "react-archer";
import useWebSocket from "react-use-websocket";
import Moment from "react-moment";

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertMsToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

const DetailedStoppingAnalysisPage = () => {
  const WS_URL = process.env.REACT_APP_WEBSOCKET_HOST || "";

  const [processTime, setProcessTime] = useState(0);

  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0,
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      WarningTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      UnplannedStops: 0,
      UnplannedStopsDetail: [],
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  const [timeString, setTimeString] = useState<Date>(new Date());

  // ** UseEffects
  useEffect(() => {
    setTimeString(new Date(Number(processTime) * 1000));
  }, [processTime]);

  const { lastMessage } = useWebSocket(WS_URL, {
    onOpen: () => true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    try {
      lastMessage && setMessage(JSON.parse(lastMessage.data));
      lastMessage && setProcessTime(JSON.parse(lastMessage.data).Time);
    } catch {}
  }, [lastMessage]);

  return (
    <Box
      sx={{
        width: "100dwv",
        height: "100dvh",
        position: "relative",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: 1,
          borderRadius: 2,
          backdropFilter: "blur(16px)",
          gap: 1,
          zIndex: 3,
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{
            background: "rgba(255, 255, 255, 0.15)",
            px: 1.5,
            height: 36,
            borderRadius: 1.5,
          }}
        >
          <Typography
            sx={{
              fontFamily: "inherit",
              fontWeight: 600,
              fontSize: "1rem",
              color: "#f0f3f4 !important",
            }}
          >
            Detaylı Duruş Analizi
          </Typography>
        </Box>
        <Box
          sx={{
            position: "relative",
            minWidth: 92,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: 1,
            borderRadius: 2,
            maxHeight: 42,
            backgroundColor: `rgba(${
              message !== undefined && message.Connected === false
                ? "255, 76, 81"
                : "2, 224, 164"
            }  , 0.21)`,
            backdropFilter: "blur(10px)",
            color: `rgb(${
              message !== undefined && message.Connected === false
                ? "255, 76, 81"
                : "2, 224, 164"
            })`,
            border: `1px solid rgb(${
              message !== undefined && message.Connected === false
                ? "255, 76, 81"
                : "2, 224, 164"
            })`,
          }}
        >
          <Box mb={1} ml={1}>
            <span className="heartbeat"></span>
            <span className="dot"></span>
          </Box>
          <Box>
            <Moment className="desc" format="HH:mm:ss">
              {timeString}
            </Moment>
          </Box>
        </Box>
      </Box>
      <Card
        sx={{
          width: "100% !important",
          height: "100% !important",
          boxShadow: "none",
          background: "transparent",
        }}
      >
        <CardContent
          sx={{
            width: "100% !important",
            height: "100% !important",
            display: "flex",
            p: "0px !important",
            justifyContent: "center",
          }}
        >
          <ArcherContainer lineStyle="curve" offset={0}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                height: "100%",
                maxWidth: "100dvw",
                maxHeight: "100dvh",
                overflow: "auto",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  mx: 3,
                }}
              >
                <ArcherElement
                  id="line-info"
                  relations={message.Machines.map((machine) => ({
                    targetId: `machine-${machine.id}-info`,
                    targetAnchor: "left",
                    sourceAnchor: "right",
                    style: {
                      strokeColor: "#48C9B0",
                      strokeWidth: 1.5,
                    },
                  }))}
                >
                  <div className="card">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        px: 1,
                      }}
                    >
                      <Box
                        sx={{
                          m: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 600,
                            fontSize: "1rem !important",
                            color: "#FBFBFC !important",
                          }}
                        >
                          Şurup Hattı
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 2,
                          px: 0.8,
                          py: 0.4,
                          minWidth: 85,
                          backgroundColor: "rgba(255, 56, 34, 0.13)",
                          ":hover": {
                            backgroundColor: "rgba(255, 56, 34, 0.21)",
                          },
                          border: "1px solid rgba(255, 56, 34)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem !important",
                            color: "rgba(255, 56, 34) !important",
                          }}
                        >
                          {convertMsToTime(message?.Line.DownTime)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 2,
                          px: 0.8,
                          py: 0.4,
                          m: 1,
                          minWidth: 85,
                          backgroundColor: "rgba(243, 156, 18, 0.13)",
                          ":hover": {
                            backgroundColor: "rgba(243, 156, 18, 0.21)",
                          },
                          border: "1px solid rgb(243, 156, 18)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem !important",
                            color: "rgb(243, 156, 18) !important",
                          }}
                        >
                          {convertMsToTime(message?.Line.WarningTime)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          borderRadius: 2,
                          px: 0.8,
                          py: 0.4,
                          mb: 1,
                          minWidth: 85,
                          backgroundColor: "rgba(6, 255, 89, 0.13)",
                          ":hover": {
                            backgroundColor: "rgba(6, 255, 89, 0.21)",
                          },
                          border: "1px solid rgb(6, 255, 89)",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "0.9rem !important",
                            color: "rgb(6, 255, 89) !important",
                          }}
                        >
                          {convertMsToTime(message?.Line.WorkingTime)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </ArcherElement>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  mx: 3,
                }}
              >
                {message.Machines.map((machine) => (
                  <ArcherElement
                    id={`machine-${machine.id}-info`}
                    relations={[
                      {
                        targetId: `machine-${machine.id}-sensors-info`,
                        targetAnchor: "left",
                        sourceAnchor: "right",
                        style: {
                          strokeColor: "#E74C3C",
                          strokeWidth: 1.5,
                        },
                      },
                    ]}
                  >
                    <div className="card">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          px: 1,
                        }}
                      >
                        <Box
                          sx={{
                            m: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 600,
                              fontSize: "1rem !important",
                              color: "#FBFBFC !important",
                            }}
                          >
                            {machine.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 2,
                            px: 0.8,
                            py: 0.4,
                            minWidth: 85,
                            backgroundColor: "rgba(255, 56, 34, 0.13)",
                            ":hover": {
                              backgroundColor: "rgba(255, 56, 34, 0.21)",
                            },
                            border: "1px solid rgba(255, 56, 34)",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.9rem !important",
                              color: "rgba(255, 56, 34) !important",
                            }}
                          >
                            {convertMsToTime(machine.DownTime)}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 2,
                            px: 0.8,
                            py: 0.4,
                            m: 1,
                            minWidth: 85,
                            backgroundColor: "rgba(243, 156, 18, 0.13)",
                            ":hover": {
                              backgroundColor: "rgba(243, 156, 18, 0.21)",
                            },
                            border: "1px solid rgb(243, 156, 18)",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.9rem !important",
                              color: "rgb(243, 156, 18) !important",
                            }}
                          >
                            {convertMsToTime(machine.WarningTime)}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            borderRadius: 2,
                            px: 0.8,
                            py: 0.4,
                            mb: 1,
                            minWidth: 85,
                            backgroundColor: "rgba(6, 255, 89, 0.13)",
                            ":hover": {
                              backgroundColor: "rgba(6, 255, 89, 0.21)",
                            },
                            border: "1px solid rgb(6, 255, 89)",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "0.9rem !important",
                              color: "rgb(6, 255, 89) !important",
                            }}
                          >
                            {convertMsToTime(machine.RunTime)}
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </ArcherElement>
                ))}
              </Box>
              <Box
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  mx: 3,
                }}
              >
                {message.Machines.map((machine) => (
                  <ArcherElement id={`machine-${machine.id}-sensors-info`}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        px: 1,
                      }}
                    >
                      {machine.Sensors &&
                        machine.Sensors.map((item, index) => (
                          <div className="card" key={index}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                px: 1,
                                minWidth: 159,
                              }}
                            >
                              <Box
                                sx={{
                                  m: 1,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "0.9rem !important",
                                    color: "#FBFBFC !important",
                                  }}
                                >
                                  {item.name}
                                </Typography>
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontSize: "0.9rem !important",
                                    color: "#D0D3D4",
                                  }}
                                >
                                  Tekrar Sayısı
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  borderRadius: 2,
                                  m: 1,
                                  px: 0.8,
                                  py: 0.4,
                                  minWidth: 85,
                                  backgroundColor: "rgba(255, 56, 34, 0.13)",
                                  ":hover": {
                                    backgroundColor: "rgba(255, 56, 34, 0.21)",
                                  },
                                  border: "1px solid rgba(255, 56, 34)",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "0.9rem !important",
                                    color: "rgba(255, 56, 34) !important",
                                  }}
                                >
                                  {item.count}
                                </Typography>
                              </Box>
                              <Box display={item.time ? "flex" : "none"}>
                                <Typography
                                  sx={{
                                    fontSize: "0.9rem !important",
                                    color: "#D0D3D4",
                                  }}
                                >
                                  Duruş Süresi
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: item.time ? "flex" : "none",
                                  justifyContent: "center",
                                  borderRadius: 2,
                                  px: 0.8,
                                  py: 0.4,
                                  m: 1,
                                  minWidth: 85,
                                  backgroundColor: "rgba(255, 56, 34, 0.13)",
                                  ":hover": {
                                    backgroundColor: "rgba(255, 56, 34, 0.21)",
                                  },
                                  border: "1px solid rgba(255, 56, 34)",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "rgba(255, 56, 34) !important",
                                    fontSize: "0.9rem !important",
                                  }}
                                >
                                  {convertMsToTime(item.time as number)}
                                </Typography>
                              </Box>
                            </Box>
                          </div>
                        ))}
                    </Box>
                  </ArcherElement>
                ))}
              </Box>
            </Box>
          </ArcherContainer>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DetailedStoppingAnalysisPage;
