// ** Icons Imports
import {
  AutoModeRounded,
  CloseRounded,
  DoneOutlineRounded,
  FlagTwoTone,
  Inventory2Rounded,
  QrCodeRounded,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { Message } from "../message";
import DebugModeInfo from "./DebugModeInfo";

// ** Types
type ProductionFlowStateDialogProps = {
  messageData: Message;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const ProductionFlowStatusDialog = (props: ProductionFlowStateDialogProps) => {
  // ** Props
  const { messageData, dialogState, setDialogState } = props;

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="lg"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            Üretim Akış Durumu
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              position: "relative",
              width: "100%",
              py: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              flexWrap: "wrap",
              gap: 0.5,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                className="card"
                style={{
                  paddingTop: 8,
                  paddingRight: 14,
                  paddingBottom: 8,
                  paddingLeft: 14,
                  margin: 5,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: "0.9rem !important",
                    color: "rgb(236, 240, 241) !important",
                  }}
                >
                  Üretim Hızı: {100} adet / dk
                </Typography>
              </div>
            </Box>
          </Box>
          <div
            className="card"
            style={{
              paddingTop: 25,
              paddingBottom: 25,
              marginTop: 10,
              width: "100%",
              background: "rgba(0,0,0, 0.66)",
            }}
          >
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              alignItems="center"
            >
              <div
                className="card"
                style={{
                  padding: 10,
                  margin: 5,
                  minWidth: 160,
                  minHeight: 65,
                  borderBottom: "2px solid rgb(4, 158, 230)",
                }}
              >
                <DebugModeInfo label="LineInBottlePieces" top={-10} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.1rem",
                      lineHeight: "1.1rem",
                      color: "#CACFD2",
                      pt: 0.2,
                      pb: 2,
                    }}
                  >
                    Giren Şişe
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 42,
                      height: 42,
                      px: 0,
                      mr: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(4, 158, 230, 0.21)",
                      color: "rgba(4, 158, 230)",
                      borderBottom: "2px solid rgb(4, 158, 230)",
                    }}
                  >
                    <svg
                      style={{
                        margin: 7,
                        filter:
                          "drop-shadow(1px 3px 5px rgba(4, 158, 230, 0.81))",
                      }}
                      fill="#1F3140"
                      height="64px"
                      width="64px"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-26.68 -26.68 320.14 320.14"
                      enableBackground="new 0 0 266.777 266.777"
                      transform="rotate(0)"
                      stroke="#1F3140"
                      strokeWidth="0.00266777"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#049ee6"
                        strokeWidth="53.355399999999996"
                      >
                        <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                      </g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                      </g>
                    </svg>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.2rem",
                        lineHeight: "1.2rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {messageData.Line.InBottlePieces}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        lineHeight: "0.75rem",
                        color: "#CACFD2",
                      }}
                    >
                      Adet
                    </Typography>
                  </Box>
                </Box>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(155, 89, 182)",
                  }}
                >
                  <DebugModeInfo label="LineDolumBottleOutCounter" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Dolan Şişe
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          height: 42,
                          p: 0,
                          mr: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(155, 89, 182, 0.21)",
                          color: "rgba(155, 89, 182)",
                          borderBottom: "2px solid rgb(155, 89, 182)",
                        }}
                      >
                        <svg
                          style={{
                            margin: 6,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(155, 89, 182, 0.81))",
                          }}
                          fill="#9b59b6"
                          height="64px"
                          width="64px"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-26.68 -26.68 320.14 320.14"
                          enableBackground="new 0 0 266.777 266.777"
                          transform="rotate(0)"
                          stroke="#9b59b6"
                          strokeWidth="0.00266777"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke="#9b59b6"
                            strokeWidth="13.872404"
                          >
                            <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                          </g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                          </g>
                        </svg>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.DolumBottleOutCounter}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(255, 76, 81)",
                  }}
                >
                  <DebugModeInfo label="LineUnfillableBottlePieces" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Dolum Fire
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgba(255, 76, 81)",
                          borderBottom: "2px solid rgb(255, 76, 81)",
                        }}
                      >
                        <CloseRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.UnfillableBottlePieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo
                    label="LineVerifiedBottleTotalPieces"
                    top={-10}
                  />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Etiketli Ürün
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgba(2, 224, 164)",
                          borderBottom: "2px solid rgba(2, 224, 164)",
                        }}
                      >
                        <QrCodeRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.VerifiedBottleTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(255, 76, 81)",
                  }}
                >
                  <DebugModeInfo
                    label="LineRejectBottleTotalPieces"
                    top={-10}
                  />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Etiket Fire
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgb(255, 76, 81)",
                          borderBottom: "2px solid rgb(255, 76, 81)",
                        }}
                      >
                        <CloseRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.RejectBottleTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo label="LineVerifiedBoxTotalPieces" top={-10} />

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Kutulanan Ürün
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgb(2, 224, 164)",
                          borderBottom: "2px solid rgb(2, 224, 164)",
                        }}
                      >
                        <svg
                          style={{
                            margin: 5,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                          }}
                          width="64px"
                          height="64px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="#2, 224, 164"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M4 15.8294V15.75V8C4 7.69114 4.16659 7.40629 4.43579 7.25487L4.45131 7.24614L11.6182 3.21475L11.6727 3.18411C11.8759 3.06979 12.1241 3.06979 12.3273 3.18411L19.6105 7.28092C19.8511 7.41625 20 7.67083 20 7.94687V8V15.75V15.8294C20 16.1119 19.8506 16.3733 19.6073 16.5167L12.379 20.7766C12.1451 20.9144 11.8549 20.9144 11.621 20.7766L4.39267 16.5167C4.14935 16.3733 4 16.1119 4 15.8294Z"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M12 21V12"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M12 12L4 7.5"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M20 7.5L12 12"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.VerifiedBoxTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(243, 156, 18)",
                  }}
                >
                  <DebugModeInfo label="LineRecycleBottles" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Kutulama Tekrar
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(243, 156, 18, 0.21)",
                          color: "rgb(243, 156, 18)",
                          borderBottom: "2px solid rgb(243, 156, 18)",
                        }}
                      >
                        <AutoModeRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(243, 156, 18, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.RecycleBottles}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 3,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo
                    label="LineVerifiedBottleTotalPieces"
                    top={-10}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.1rem",
                        lineHeight: "1.1rem",
                        color: "#CACFD2",
                        pt: 0.2,
                        pb: 2,
                      }}
                    >
                      Çıkan Ürün
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(2, 224, 164, 0.21)",
                        color: "rgb(2, 224, 164)",
                        borderBottom: "2px solid rgb(2, 224, 164)",
                      }}
                    >
                      <DoneOutlineRounded
                        sx={{
                          filter:
                            "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {messageData.Line.VerifiedBottleTotalPieces}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          lineHeight: "0.75rem",
                          color: "#CACFD2",
                        }}
                      >
                        Adet
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(255, 76, 81)",
                  }}
                >
                  <DebugModeInfo label="LineRejectTotalPieces" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Toplam Fire
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgb(255, 76, 81)",
                          borderBottom: "2px solid rgb(255, 76, 81)",
                        }}
                      >
                        <CloseRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {messageData.Line.RejectTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
              <div
                className="card"
                style={{
                  padding: 10,
                  margin: 3,
                  minWidth: 160,
                  minHeight: 65,
                  borderBottom: "2px solid rgb(2, 224, 164)",
                }}
              >
                <DebugModeInfo label="LinePackageOutPieces" top={-10} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.1rem",
                      lineHeight: "1.1rem",
                      color: "#CACFD2",
                      pt: 0.2,
                      pb: 2,
                    }}
                  >
                    Çıkan Koli
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 42,
                      p: 0,
                      height: 42,
                      mr: 1.5,
                      display: "flex",
                      borderRadius: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(2, 224, 164, 0.21)",
                      color: "rgb(2, 224, 164)",
                      borderBottom: "2px solid rgb(2, 224, 164)",
                    }}
                  >
                    <Inventory2Rounded
                      sx={{
                        filter:
                          "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.2rem",
                        lineHeight: "1.2rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {messageData.Line.PackageOutPieces}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        lineHeight: "0.75rem",
                        color: "#CACFD2",
                      }}
                    >
                      Adet
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProductionFlowStatusDialog;
