import { proxy } from "valtio";

export const debugModeState = proxy<{value: boolean}>({
  value: false,
});

export const enableDebugMode = () => {
  debugModeState.value = true;
};

export const disableDebugMode = () => {
  debugModeState.value = false;
};

