// ** Icons Imports
import { CloseRounded, DoneRounded, FlagTwoTone } from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import axios from "axios";

// ** Types
type CleaningAndMaintenanceChangeDialogProps = {
  accessToken: string;
  cleaningAndMaintenanceInfo: boolean;
  setCleaningAndMaintenanceInfo: (value: React.SetStateAction<boolean>) => void;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const API_URL = process.env.REACT_APP_API_HOST || "";

const CleaningAndMaintenanceChangeDialog = (
  props: CleaningAndMaintenanceChangeDialogProps
) => {
  // ** Props
  const {
    cleaningAndMaintenanceInfo,
    setCleaningAndMaintenanceInfo,
    dialogState,
    setDialogState,
    accessToken,
  } = props;

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  // **
  const onSaveCleaningAndMaintenanceInfo = () => {
    if (cleaningAndMaintenanceInfo) {
      axios
        .post(
          API_URL + "/setStartScheduledStops",
          {
            val: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-token": accessToken,
            },
          }
        )
        .then((result) => {
          if (result.data === false) {
            setCleaningAndMaintenanceInfo(false);
            setDialogState(false);
          }
        });
    } else {
      axios
        .post(
          API_URL + "/setStartScheduledStops",
          {
            val: true,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "x-token": accessToken,
            },
          }
        )
        .then((result) => {
          if (result.data === true) {
            setCleaningAndMaintenanceInfo(true);
            setDialogState(false);
          }
        });
    }
  };

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            {cleaningAndMaintenanceInfo ? "Temizlik ve Bakımı Bitir" : "Temizlik ve Bakım Başlat"}
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5 }}>
        <Box
          sx={{
            width: "100%",
            minHeight: 100,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
            }}
          >
            {cleaningAndMaintenanceInfo
              ? "Temizlik ve bakımı bitirmek için onay gerekiyor."
              : "Temizlik ve bakımı başlatmak için operatör seçimi ve onay gerekiyor."}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            color: "#f0f3f4",
            fontSize: "1.3rem",
            borderColor: "rgba(255, 255, 255, 0.36)",
          }}
          onClick={() => setDialogState(false)}
        >
          İptal
        </Button>
        <Button
          sx={{
            fontSize: "1.3rem",
            color: "#f0f3f4",
            background: "rgba(255, 255, 255, 0.36)",
          }}
          variant="contained"
          onClick={onSaveCleaningAndMaintenanceInfo}
          startIcon={
            <DoneRounded
              sx={{
                fontSize: "1.3rem",
              }}
            />
          }
        >
          Onayla
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CleaningAndMaintenanceChangeDialog;
