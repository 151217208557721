// ** Icons Imports
import {
  CloseRounded,
  FlagTwoTone,
  NoPhotographyRounded,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import FeederSensor1 from "../assets/images/feeder-sensor-1.png";
import FeederSensor2 from "../assets/images/feeder-sensor-2.png";

import FillingSensor1 from "../assets/images/filling-sensor-1.png";

import TagSensor1 from "../assets/images/tag-sensor-1.png";
import TagSensor2 from "../assets/images/tag-sensor-2.png";
import TagSensor3 from "../assets/images/tag-sensor-3.png";

import BoxingSensor1 from "../assets/images/boxing-sensor-1.png";
import BoxingSensor2 from "../assets/images/boxing-sensor-2.png";
import BoxingSensor3 from "../assets/images/boxing-sensor-3.png";
import BoxingSensor4 from "../assets/images/boxing-sensor-4.png";
import BoxingSensor5 from "../assets/images/boxing-sensor-5.png";
import BoxingSensor6 from "../assets/images/boxing-sensor-6.png";

import PackingSensor1 from "../assets/images/packing-sensor-1.png";
import PackingSensor2 from "../assets/images/packing-sensor-2.png";
import { useEffect, useState } from "react";

export enum SelectedPhotoEnumType {
  unknown = "unknown",
  feederSensor1 = "feederSensor1",
  feederSensor2 = "feederSensor2",
  fillingSensor1 = "fillingSensor1",
  tagSensor1 = "tagSensor1",
  tagSensor2 = "tagSensor2",
  tagSensor3 = "tagSensor3",

  boxingSensor1 = "boxingSensor1",
  boxingSensor2 = "boxingSensor2",
  boxingSensor3 = "boxingSensor3",
  boxingSensor4 = "boxingSensor4",
  boxingSensor5 = "boxingSensor5",
  boxingSensor6 = "boxingSensor6",

  packingSensor1 = "packingSensor1",
  packingSensor2 = "packingSensor2",
}

// ** Types
type SelectedMachineSensorInfoDialogProps = {
  dialogState: boolean;
  selectedSensorId: number;
  name: string;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const SelectedMachineSensorInfoDialog = (
  props: SelectedMachineSensorInfoDialogProps
) => {
  // ** Props
  const { name, dialogState, setDialogState, selectedSensorId } =
    props;

  // ** State
  const [title, setTitle] = useState<string>("");
  const [imageSrc, setImageSrc] = useState<any>();
  const [imageAlt, setImageAlt] = useState<string>("");

  useEffect(() => {
    if (name === 'Şişe Besleme') {
      setTitle("Besleme - Sensör Yerleri");

      if (selectedSensorId === 1) {
        setImageSrc(FeederSensor2);
        setImageAlt("Besleme - Çıkış Sensörü");
      } else if (selectedSensorId === 2) {
        setImageSrc(FeederSensor1);
        setImageAlt("Besleme - Birikme Sensörü");
      } else {
        setImageSrc(undefined);
        setImageAlt("Fotoğraf Bulunamadı");
      }
    } else if (name === 'Dolum') {
      setTitle("Dolum - Sensör Yerleri");

      if (selectedSensorId === 1) {
        setImageSrc(FillingSensor1);
        setImageAlt("Dolum - Şişe Giriş Sensörü");
      } else {
        setImageSrc(undefined);
        setImageAlt("Fotoğraf Bulunamadı");
      }
    } else if (name === 'Etiket') {
      setTitle("Etiket - Sensör Yerleri");

      if (selectedSensorId === 1) {
        setImageSrc(TagSensor3);
        setImageAlt("Etiket - Ürün Çıkış Sensörü");
      } else if (selectedSensorId === 2) {
        setImageSrc(TagSensor2);
        setImageAlt("Etiket - Ret Sensörü");
      } else if (selectedSensorId === 5) {
        setImageSrc(TagSensor1);
        setImageAlt("Etiket - Ürün Birikme Sensörü");
      } else {
        setImageSrc(undefined);
        setImageAlt("Fotoğraf Bulunamadı");
      }
    } else if (name === 'Kutulama') {
      setTitle("Kutulama - Sensör Yerleri");

      if (selectedSensorId === 1) {
        setImageSrc(BoxingSensor1);
        setImageAlt("Kutulama - Şişe Giriş Sensörü");
      } else if (selectedSensorId === 2) {
        setImageSrc(BoxingSensor2);
        setImageAlt("Kutulama - Eksik Ürün Ret Sensörü");
      } else if (selectedSensorId === 4) {
        setImageSrc(BoxingSensor3);
        setImageAlt("Kutulama - Devirme Hatası Sensörü");
      } else if (selectedSensorId === 6) {
        setImageSrc(BoxingSensor4);
        setImageAlt("Kutulama - Spoon Kaşık Sensörü");
      } else if (selectedSensorId === 5) {
        setImageSrc(BoxingSensor5);
        setImageAlt("Kutulama - Box Kutu Sensörü");
      } else if (selectedSensorId === 8) {
        setImageSrc(BoxingSensor6);
        setImageAlt("Kutulama - Out Box Çıkan Ürün Sensörü");
      } else {
        setImageSrc(undefined);
        setImageAlt("Fotoğraf Bulunamadı");
      }
    } else if (name === 'Kolileme') {
      setTitle("Kolileme - Sensör Yerleri");

      if (selectedSensorId === 3) {
        setImageSrc(PackingSensor1);
        setImageAlt("Kolileme - Giriş Sıkışma Sensörü");
      } else if (selectedSensorId === 2) {
        setImageSrc(PackingSensor2);
        setImageAlt("Kolileme - Çıkan Koli Sensörü");
      } else {
        setImageSrc(undefined);
        setImageAlt("Fotoğraf Bulunamadı");
      }
    } else {
      setTitle("");
    }
  }, [name, selectedSensorId]);

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          background: "rgba(255, 255, 255, 0.13) !important",
          backdropFilter: "blur(16px)",
        },
      }}
      onClose={() => {
        setDialogState(false);
        setImageSrc(undefined);
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            background: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              background: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.75)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            {title}
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={() => {
              setDialogState(false);
            }}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5 }}>
        <Box
          sx={{
            width: "100%",
            px: 2,
            pb: 3,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            gap: 3,
          }}
        >
          <Box
            sx={{
              width: "85%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#212f3d",
                borderTopRightRadius: 7,
                borderTopLeftRadius: 7,
                padding: 1,
              }}
            >
              <Typography
                sx={{
                  color: "#f0f3f4",
                  fontWeight: 600,
                }}
              >
                {imageAlt}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 12,
              }}
            >
              {imageSrc ? (
                <img
                  width={"100%"}
                  height={"auto"}
                  style={{
                    borderBottomRightRadius: 7,
                    borderBottomLeftRadius: 7,
                  }}
                  src={imageSrc}
                  alt={imageAlt}
                />
              ) : (
                <Box
                  sx={{
                    width: "100%",
                    aspectRatio: "210 / 157",
                    p: 0,
                    display: "flex",
                    borderBottomRightRadius: 2,
                    borderBottomLeftRadius: 2,
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "rgba(236, 240, 241, 0.21)",
                    color: "rgb(236, 240, 241)",
                  }}
                >
                  <NoPhotographyRounded
                    sx={{
                      fontSize: 32,
                    }}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectedMachineSensorInfoDialog;
