import React, { useCallback, useEffect, useState } from "react";
import MachineLight from "../components/MachineLight";
import type {} from "@mui/x-data-grid/themeAugmentation";
import { useKeyPress } from "@react-typed-hooks/use-key-press";

import {
  Box,
  Button,
  Container,
  IconButton,
  LinearProgress,
  Menu,
  MenuProps,
  Tooltip,
  Typography,
  linearProgressClasses,
  styled,
} from "@mui/material";
import {
  AccessTimeRounded,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  AutoModeRounded,
  BarChartRounded,
  CloseRounded,
  DoneOutlineRounded,
  InfoTwoTone,
  Inventory2Rounded,
  KeyboardArrowDown,
  QrCodeRounded,
  StormRounded,
  SwitchAccessShortcutAddRounded,
  ThreeSixtyRounded,
} from "@mui/icons-material";
import Productivity from "../components/Productivity";
import SelectedMachineInfoDialog from "../components/SelectedMachineInfoDialog";
import ProductivityInfoDialog from "../components/ProductivityInfoDialog";
import axios from "axios";
import { useParams } from "react-router-dom";
import DebugModeInfo from "../components/DebugModeInfo";
import dateFormat from "dateformat";
import { i18n } from "dateformat";
import { disableDebugMode, enableDebugMode } from "../global-states/debug-mode";
import { Line, Machines, Message } from "../message";

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const convertMsToTime = (seconds: number) => {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;
  hours = hours % 24;

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds
  )}`;
};

// ** Styled Components
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 7,
    backgroundColor: "rgba(255, 255, 255, 0.12)",
    backdropFilter: "blur(16px)",
    minWidth: 136,
    boxShadow: "0px 0px 15px 15px rgba(0,0,0,0.1)",
    "& .MuiMenu-list": {
      padding: 0,
    },
    "& .MuiMenuItem-root": {
      borderRadius: 4,
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "#f0f3f4",
      },
      "&:active": {
        backgroundColor: "rgba(255, 255, 255, 0.17)",
      },
    },
  },
}));

const PageShiftDetailsPage = () => {
  // ** States
  const [lineStateRatio, setLineStateRatio] = useState<number>(0);

  // ** States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);

  // ** Seçili menü fonksiyonu tetiklendiğinde
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  // ** Menüyü kapat
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [productivityInfoDialogState, setProductivityInfoDialogState] =
    useState<boolean>(false);

  const [selectedMachine, setSelectedMachine] = useState<Machines[0] | null>(
    null
  );

  const caps = useKeyPress({ targetKey: "CapsLock" });

  useEffect(() => {
    if (caps) {
      enableDebugMode();
    } else {
      disableDebugMode();
    }
  }, [caps]);

  const API_URL = process.env.REACT_APP_API_HOST || "";

  const [message, setMessage] = useState<Message>({
    Machines: [],
    Line: {
      Availability: 0,
      Performance: 0,
      Quality: 0,
      OEE: 0,
      Breaks: 0,
      Breaks1: 0,
      Breaks2: 0,
      DolumBottleOutCounter: 0, // ** Dolan Şişe
      DolumTurnTableFull: false,
      DownTime: 0,
      GoodCount: 0,
      Red: false,
      Yellow: false,
      Green: false,
      IdealRunRatePPM: 0,
      InBottlePieces: 0, // ** Giren Şişe
      KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
      PackageOutPieces: 0, // ** Çıkan Koli
      PlannedProductionTime: 0,
      PlannedStops: 0,
      PlannedStops1: 0,
      PlannedStops2: 0,
      PresentTime: 0,
      RecycleBottles: 0,
      RejectBottleTotalPieces: 0, // ** Etiket Fire
      RejectTotalPieces: 0, // ** Toplam Fire
      VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
      UnplannedStops: 0,
      UnplannedStopsDetail: [],
      UnfillableBottlePieces: 0, // ** Dolum Fire
      VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
      WarningTime: 0,
      WorkingTime: 0,
      ShiftLength: 0,
      StartProduction: false,
      StartBreaks: false,
      StartScheduledStops: false,
    },
    Time: 0,
    Connected: false,
  });

  const [productivityDatas, setProductivityDatas] = useState<Line>({
    Availability: 0,
    Performance: 0,
    Quality: 0,
    OEE: 0,
    Breaks: 0,
    Breaks1: 0,
    Breaks2: 0,
    DolumBottleOutCounter: 0, // ** Dolan Şişe
    DolumTurnTableFull: false,
    DownTime: 0,
    GoodCount: 0,
    Red: false,
    Yellow: false,
    Green: false,
    IdealRunRatePPM: 0,
    InBottlePieces: 0, // ** Giren Şişe
    KutulamaBoxRejectCounter: 0, // ** Kutulama Fire
    PackageOutPieces: 0, // ** Çıkan Koli
    PlannedProductionTime: 0,
    PlannedStops: 0,
    PlannedStops1: 0,
    PlannedStops2: 0,
    PresentTime: 0,
    RecycleBottles: 0,
    RejectBottleTotalPieces: 0, // ** Etiket Fire
    RejectTotalPieces: 0, // ** Toplam Fire
    VerifiedBoxTotalPieces: 0, // ** Kutulanan Ürün
    UnplannedStops: 0,
    UnplannedStopsDetail: [],
    UnfillableBottlePieces: 0, // ** Dolum Fire
    VerifiedBottleTotalPieces: 0, // ** Etiketli Ürün - Çıkan Ürün
    WarningTime: 0,
    WorkingTime: 0,
    ShiftLength: 0,
    StartProduction: false,
    StartBreaks: false,
    StartScheduledStops: false,
  });

  const getLineState = (line: any) => {
    if (line.Green) {
      return "2, 224, 164";
    }

    if (line.Red) {
      return "255, 76, 81";
    }

    if (line.Yellow) {
      return "243, 156, 18";
    }

    return "236, 240, 241";
  };

  const { shiftId } = useParams();

  i18n.dayNames = [
    "Paz",
    "Pzt",
    "Sal",
    "Çar",
    "Per",
    "Cum",
    "Cmt",
    "Pazar",
    "Pazartesi",
    "Salı",
    "Çarşamba",
    "Perşembe",
    "Cuma",
    "Cumartesi",
  ];

  useEffect(() => {
    setLineStateRatio(
      Number(
        (
          ((message.Line.PresentTime ? message.Line.PresentTime : 0) / 43200) *
          100
        ).toFixed(2)
      )
    );
  }, [message.Line.PresentTime]);

  const getShiftDataDetails = useCallback(async () => {
    const shiftData = await axios.get(`${API_URL}/getShiftData/${shiftId}`);
    if (shiftData.data) {
      setMessage(shiftData.data);
    }
  }, []);

  useEffect(() => {
    getShiftDataDetails();
  }, [getShiftDataDetails]);

  useEffect(() => {
    setProductivityDatas({ ...message.Line });
  }, [message]);

  const onReduceOne = () => {
    if (Number(shiftId) > 0) {
      window.open(`/shift-details/${Number(shiftId) - 1}`, "_parent");
    }
  };

  const onAddOne = () => {
    const maxShiftId = localStorage.getItem("maxShiftId");

    console.log(maxShiftId);
    if (Number(shiftId) < Number(maxShiftId)) {
      window.open(`/shift-details/${Number(shiftId) + 1}`, "_parent");
    }
  };

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        overflowY: "auto",
        height: "100%",
        maxHeight: "100dvh",
      }}
    >
      <Box
        sx={{
          position: "fixed",
          top: 9,
          right: 15,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          backdropFilter: "blur(16px)",
          p: 1,
          gap: 1,
          zIndex: 3,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-end",
          }}
        >
          <Button
            id="report-customized-filter-button"
            aria-controls={open ? "report-customized-filter-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            variant="text"
            disableElevation
            onClick={handleClick}
            sx={{
              pl: 1,
              pr: 2,
              py: 1,
              color: "#f0f3f4",
              textTransform: "capitalize",
              fontSize: "1rem",
              backgroundColor: "rgba(255, 255, 255, 0.11)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.21)",
              },
            }}
            endIcon={<KeyboardArrowDown />}
          >
            <Box display="flex" justifyContent="flex-end">
              <BarChartRounded
                sx={{
                  color: "#f0f3f4 !important",
                  mr: 1,
                }}
              />
              Rapor Al
            </Box>
          </Button>
          <StyledMenu
            id="report-customized-filter-menu"
            MenuListProps={{
              "aria-labelledby": "report-customized-filter-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              mt: 1,
            }}
          >
            <Box
              sx={{
                width: "calc(100% - 16px)",
              }}
            >
              <Button
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  m: 1,
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0, 0.21)",
                  },
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32px"
                  height="32px"
                  viewBox="0 0 56 64"
                  enable-background="new 0 0 56 64"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <g>
                      <path
                        fill="#8C181A"
                        d="M5.1,0C2.3,0,0,2.3,0,5.1v53.8C0,61.7,2.3,64,5.1,64h45.8c2.8,0,5.1-2.3,5.1-5.1V20.3L37.1,0H5.1z"
                      ></path>
                      <path
                        fill="#6B0D12"
                        d="M56,20.4v1H43.2c0,0-6.3-1.3-6.1-6.7c0,0,0.2,5.7,6,5.7H56z"
                      ></path>
                      <path
                        opacity="0.5"
                        fill="#FFFFFF"
                        enable-background="new "
                        d="M37.1,0v14.6c0,1.7,1.1,5.8,6.1,5.8H56L37.1,0z"
                      ></path>
                    </g>
                    <path
                      fill="#FFFFFF"
                      d="M14.9,49h-3.3v4.1c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h3.7 c2.4,0,3.8,1.7,3.8,3.6C18.7,47.4,17.3,49,14.9,49z M14.8,43.1h-3.2v4.6h3.2c1.4,0,2.4-0.9,2.4-2.3C17.2,44,16.2,43.1,14.8,43.1z M25.2,53.8h-3c-0.6,0-1.1-0.5-1.1-1.1v-9.8c0-0.6,0.5-1.1,1.1-1.1h3c3.7,0,6.2,2.6,6.2,6C31.4,51.2,29,53.8,25.2,53.8z M25.2,43.1 h-2.6v9.3h2.6c2.9,0,4.6-2.1,4.6-4.7C29.9,45.2,28.2,43.1,25.2,43.1z M41.5,43.1h-5.8V47h5.7c0.4,0,0.6,0.3,0.6,0.7 s-0.3,0.6-0.6,0.6h-5.7v4.8c0,0.4-0.3,0.7-0.8,0.7c-0.4,0-0.7-0.3-0.7-0.7V42.9c0-0.6,0.5-1.1,1.1-1.1h6.2c0.4,0,0.6,0.3,0.6,0.7 C42.2,42.8,41.9,43.1,41.5,43.1z"
                    ></path>
                  </g>
                </svg>
                <Typography
                  sx={{
                    ml: 2,
                    color: "#f0f3f4",
                  }}
                >
                  PDF
                </Typography>
              </Button>
              <Button
                fullWidth
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  m: 1,
                  borderRadius: 1,
                  "&:hover": {
                    backgroundColor: "rgba(0,0,0, 0.21)",
                  },
                }}
              >
                <svg
                  width="32px"
                  height="32px"
                  viewBox="-4 0 64 64"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M5.112.006c-2.802 0-5.073 2.273-5.073 5.074v53.841c0 2.803 2.271 5.074 5.073 5.074h45.774c2.801 0 5.074-2.271 5.074-5.074v-38.605l-18.902-20.31h-31.946z"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="#45B058"
                    ></path>
                    <path
                      d="M19.429 53.938c-.216 0-.415-.09-.54-.27l-3.728-4.97-3.745 4.97c-.126.18-.324.27-.54.27-.396 0-.72-.306-.72-.72 0-.144.035-.306.144-.432l3.89-5.131-3.619-4.826c-.09-.126-.145-.27-.145-.414 0-.342.288-.72.721-.72.216 0 .432.108.576.288l3.438 4.628 3.438-4.646c.127-.18.324-.27.541-.27.378 0 .738.306.738.72 0 .144-.036.288-.127.414l-3.619 4.808 3.891 5.149c.09.126.125.27.125.414 0 .396-.324.738-.719.738zm9.989-.126h-5.455c-.595 0-1.081-.486-1.081-1.08v-10.317c0-.396.324-.72.774-.72.396 0 .721.324.721.72v10.065h5.041c.359 0 .648.288.648.648 0 .396-.289.684-.648.684zm6.982.216c-1.782 0-3.188-.594-4.213-1.495-.162-.144-.234-.342-.234-.54 0-.36.27-.756.702-.756.144 0 .306.036.433.144.828.738 1.98 1.314 3.367 1.314 2.143 0 2.826-1.152 2.826-2.071 0-3.097-7.111-1.386-7.111-5.672 0-1.98 1.764-3.331 4.123-3.331 1.548 0 2.881.468 3.853 1.278.162.144.253.342.253.54 0 .36-.307.72-.703.72-.145 0-.307-.054-.432-.162-.883-.72-1.98-1.044-3.079-1.044-1.44 0-2.467.774-2.467 1.909 0 2.701 7.112 1.152 7.112 5.636 0 1.748-1.188 3.53-4.43 3.53z"
                      fill="#ffffff"
                    ></path>
                    <path
                      d="M55.953 20.352v1h-12.801s-6.312-1.26-6.127-6.707c0 0 .207 5.707 6.002 5.707h12.926z"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="#349C42"
                    ></path>
                    <path
                      d="M37.049 0v14.561c0 1.656 1.104 5.791 6.104 5.791h12.801l-18.905-20.352z"
                      opacity=".5"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      fill="#ffffff"
                    ></path>
                  </g>
                </svg>
                <Typography
                  sx={{
                    ml: 2,
                    color: "#f0f3f4",
                  }}
                >
                  Excel
                </Typography>
              </Button>
            </Box>
          </StyledMenu>
        </Box>
        {
          /*
        
        <Tooltip title="Öncesi" placement="bottom">
          <IconButton
            sx={{
              width: 32,
              height: 32,
              marginRight: 0.1,
              borderRadius: 1,
              backgroundColor:
                Number(shiftId) === 0
                  ? "1px solid rgba(236, 240, 241, 0.13)"
                  : "1px solid rgba(52, 152, 219, 0.13)",
              ":hover": {
                backgroundColor:
                  Number(shiftId) === 0
                    ? "1px solid rgba(236, 240, 241, 0.21)"
                    : "1px solid rgba(52, 152, 219, 0.21)",
              },
              border:
                Number(shiftId) === 0
                  ? "1px solid rgba(236, 240, 241, 0.24)"
                  : "1px solid rgba(52, 152, 219, 0.24)",
              zIndex: 9,
              backdropFilter: "blur(16px)",
            }}
            onClick={() => {
              onReduceOne();
            }}
          >
            <ArrowLeftOutlined
              fontSize="small"
              sx={{
                color: Number(shiftId) === 0 ? "#f4f6f7 !important" : "#3498db",
              }}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title="Sonrası" placement="bottom">
          <IconButton
            sx={{
              width: 32,
              height: 32,
              marginRight: 0.1,
              borderRadius: 1,
              backgroundColor:
                Number(shiftId) === Number(localStorage.getItem("maxShiftId"))
                  ? "1px solid rgba(236, 240, 241, 0.13)"
                  : "1px solid rgba(52, 152, 219, 0.13)",
              ":hover": {
                backgroundColor:
                  Number(shiftId) === Number(localStorage.getItem("maxShiftId"))
                    ? "1px solid rgba(236, 240, 241, 0.21)"
                    : "1px solid rgba(52, 152, 219, 0.21)",
              },
              border:
                Number(shiftId) === Number(localStorage.getItem("maxShiftId"))
                  ? "1px solid rgba(236, 240, 241, 0.24)"
                  : "1px solid rgba(52, 152, 219, 0.24)",
              zIndex: 9,
            }}
            onClick={() => {
              onAddOne();
            }}
          >
            <ArrowRightOutlined
              fontSize="small"
              sx={{
                color:
                  Number(shiftId) === Number(localStorage.getItem("maxShiftId"))
                    ? "#f4f6f7 !important"
                    : "#3498db",
              }}
            />
          </IconButton>
        </Tooltip> */ }
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
          paddingTop: 3,
        }}
      >
        <Box
          sx={{
            minWidth: 128,
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: 1,
              flexWrap: "wrap",
            }}
          >
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                fontSize: "1.3rem !important",
                color: "#FBFBFC",
                animation: "lights 5s 750ms linear infinite",
                "@keyframes lights": {
                  "0%": {
                    color: "rgba",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.2), 0 0 0.13em hsla(320, 100%, 60%, 0.3), -1em -0.13em 0.5em hsla(40, 100%, 60%, 0), 1em 0.13em 0.5em hsla(200, 100%, 60%, 0)",
                  },
                  "30%": {
                    color: "hsl(230, 80%, 90%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 60%, 0.5), -0.5em -0.13em 0.25em hsla(40, 100%, 60%, 0.2), 0.5em 0.13em 0.25em hsla(200, 100%, 60%, 0.4)",
                  },

                  "40%": {
                    color: "hsl(230, 100%, 95%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 90%, 0.5), -0.25em -0.13em 0.13em hsla(40, 100%, 60%, 0.2), 0.25em 0.13em 0.13em hsla(200, 100%, 60%, 0.4)",
                  },

                  "70%": {
                    color: "hsl(230, 80%, 90%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.5), 0 0 0.13em hsla(320, 100%, 60%, 0.5), 0.5em -0.13em 0.25em hsla(40, 100%, 60%, 0.2), -0.5em 0.13em 0.25em hsla(200, 100%, 60%, 0.4)",
                  },
                  "100%": {
                    color: "hsl(230, 40%, 80%)",
                    textShadow:
                      "0 0 1em hsla(320, 100%, 50%, 0.2), 0 0 0.13em hsla(320, 100%, 60%, 0.3), 1em -0.13em 0.5em hsla(40, 100%, 60%, 0), -1em 0.13em 0.5em hsla(200, 100%, 60%, 0)",
                  },
                },
              }}
            >
              Kayıtlı Veri İle
            </Typography>
            <Typography
              align="center"
              sx={{
                fontWeight: 600,
                fontSize: "1.3rem !important",
                color: "#C9CFF2",
              }}
            >
              ŞURUP ÜRETİM HATTI İZLEME PANELİ
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            className="card"
            style={{
              paddingBottom: 4,
              backgroundColor: `rgba(${getLineState(message.Line)}, 0.21)`,
              color: `rgb(${getLineState(message.Line)})`,
              border: `1px solid rgb(${getLineState(message.Line)})`,
            }}
          >
            <Box
              sx={{
                width: "100%",
                py: 1,
                px: 1.5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  minWidth: 68,
                }}
              >
                <Typography
                  align="left"
                  sx={{
                    fontWeight: 500,
                    fontSize: "1rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  {convertMsToTime(message.Line.PresentTime)}
                </Typography>
              </Box>
              <Box>
                <Typography
                  align="center"
                  sx={{
                    fontWeight: 600,
                    fontSize: "1.2rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  {`${dateFormat(message.Time * 1000, "dd.mm.yyyy, dddd")} - ${
                    message.Text
                  }`}{" "}
                  Vardiya Bilgileri
                </Typography>
              </Box>
              <Box
                sx={{
                  minWidth: 52,
                }}
              >
                <Typography
                  align="right"
                  sx={{
                    fontWeight: 500,
                    fontStyle: "italic",
                    fontSize: "1rem !important",
                    color: `rgb(${getLineState(message.Line)})`,
                  }}
                >
                  {lineStateRatio && lineStateRatio.toFixed(2)} %
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "calc(100% - 16px)",
                height: 10,
                mx: 1,
                pb: 2,
              }}
            >
              <LinearProgress
                sx={{
                  height: 10,
                  borderRadius: 5,
                  [`&.${linearProgressClasses.colorPrimary}`]: {
                    backgroundColor: `rgba(${getLineState(
                      message.Line
                    )}, 0.21)`,
                  },
                  [`& .${linearProgressClasses.bar}`]: {
                    borderRadius: 5,
                    backgroundColor: `rgb(${getLineState(message.Line)})`,
                  },
                }}
                variant="determinate"
                value={lineStateRatio}
              />
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              flexDirection="column"
              flexWrap="wrap"
              alignItems="center"
            >
              <Box
                width="100%"
                display="flex"
                justifyContent="center"
                flexDirection="column"
                flexWrap="wrap"
                alignItems="center"
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      marginLeft: 9,
                      borderTop: "1px solid rgb(255, 76, 81)",
                    }}
                  >
                    <DebugModeInfo label="LineDownTime" top={-10} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          display: "flex",
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgba(255, 76, 81)",
                        }}
                      >
                        <AccessTimeRounded
                          sx={{
                            fontSize: 48,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Duruş Süresi
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC !important",
                          }}
                        >
                          {convertMsToTime(message.Line.DownTime)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      minWidth: 180,
                      borderTop: "1px solid rgb(243, 156, 18)",
                    }}
                  >
                    <DebugModeInfo label="LineWarningTime" top={-10} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(243, 156, 18, 0.21)",
                          color: "rgb(243, 156, 18)",
                        }}
                      >
                        <AccessTimeRounded
                          sx={{
                            fontSize: 48,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Mola Süresi
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {convertMsToTime(message.Line.WarningTime)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      minWidth: 180,
                      borderTop: "1px solid rgb(2, 224, 164)",
                    }}
                  >
                    <DebugModeInfo label="LineWorkingTime" top={-10} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          display: "flex",
                          borderRadius: "50%",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgb(2, 224, 164)",
                        }}
                      >
                        <AccessTimeRounded
                          sx={{
                            fontSize: 48,
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Çalışma Süresi
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {convertMsToTime(message.Line.WorkingTime)}
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      marginRight: 9,
                      borderTop: "1px solid rgb(236, 240, 241)",
                    }}
                  >
                    <DebugModeInfo label="LineIdealRunRatePPM" top={-10} />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(236, 240, 241, 0.21)",
                          color: "rgb(236, 240, 241)",
                          borderBottom: "2px solid rgb(236, 240, 241)",
                        }}
                      >
                        <ThreeSixtyRounded
                          sx={{
                            fontSize: 32,
                            margin: 5,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(236, 240, 241, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Hat Hızı
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.IdealRunRatePPM}
                        </Typography>
                        <Typography
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontWeight: 700,
                            fontSize: "0.7rem",
                            lineHeight: "0.7rem",
                            color: "#CACFD2",
                          }}
                        >
                          adet / dk
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      borderBottom: "1px solid rgb(2, 224, 164)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineVerifiedBottleTotalPieces"
                      top={-10}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgb(2, 224, 164)",
                          borderBottom: "2px solid rgb(2, 224, 164)",
                        }}
                      >
                        <svg
                          style={{
                            margin: 5,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                          }}
                          width="64px"
                          height="64px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="#2, 224, 164"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M4 15.8294V15.75V8C4 7.69114 4.16659 7.40629 4.43579 7.25487L4.45131 7.24614L11.6182 3.21475L11.6727 3.18411C11.8759 3.06979 12.1241 3.06979 12.3273 3.18411L19.6105 7.28092C19.8511 7.41625 20 7.67083 20 7.94687V8V15.75V15.8294C20 16.1119 19.8506 16.3733 19.6073 16.5167L12.379 20.7766C12.1451 20.9144 11.8549 20.9144 11.621 20.7766L4.39267 16.5167C4.14935 16.3733 4 16.1119 4 15.8294Z"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M12 21V12"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M12 12L4 7.5"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M20 7.5L12 12"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Kutulanan Ürün
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.VerifiedBottleTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontWeight: 700,
                            fontSize: "0.7rem",
                            lineHeight: "0.7rem",
                            color: "#CACFD2",
                          }}
                        >
                          adet
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      borderBottom: "1px solid rgb(243, 156, 18)",
                    }}
                  >
                    <DebugModeInfo
                      label="LineVerifiedBoxTotalPieces"
                      top={-10}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(243, 156, 18, 0.21)",
                          color: "rgb(243, 156, 18)",
                          borderBottom: "2px solid rgb(243, 156, 18)",
                        }}
                      >
                        <AutoModeRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(243, 156, 18, 0.81))",
                          }}
                        />
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Kutulama Tekrar
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.RecycleBottles}
                        </Typography>
                        <Typography
                          sx={{
                            position: "absolute",
                            bottom: 0,
                            fontWeight: 700,
                            fontSize: "0.7rem",
                            lineHeight: "0.7rem",
                            color: "#CACFD2",
                          }}
                        >
                          adet
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      cursor: "pointer",
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      borderBottom: "1px solid rgb(255, 139, 0)",
                    }}
                    onClick={() => setProductivityInfoDialogState(true)}
                  >
                    <DebugModeInfo label="LineWorkingTime" top={-10} />
                    <InfoTwoTone
                      sx={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        fontSize: "1.1rem",
                        color: "#d0d3d4",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 139, 0, 0.21)",
                          color: "rgb(255, 139, 0)",
                          borderBottom: "2px solid rgb(255, 139, 0)",
                        }}
                      >
                        <SwitchAccessShortcutAddRounded
                          sx={{
                            fontSize: 32,
                            margin: 5,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 139, 0, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Kullanılabilirlik
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.Availability
                            ? message.Line.Availability.toFixed(1)
                            : 0}
                          %
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <div
                    className="card"
                    style={{
                      cursor: "pointer",
                      width: 210,
                      paddingTop: 8,
                      paddingRight: 12,
                      paddingBottom: 8,
                      paddingLeft: 12,
                      margin: 5,
                      borderBottom: "1px solid rgb(253, 202, 64)",
                    }}
                    onClick={() => setProductivityInfoDialogState(true)}
                  >
                    <DebugModeInfo label="LineWorkingTime" top={-10} />
                    <InfoTwoTone
                      sx={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        fontSize: "1.1rem",
                        color: "#d0d3d4",
                      }}
                    />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        minHeight: 72,
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(253, 202, 64, 0.21)",
                          color: "rgb(253, 202, 64)",
                          borderBottom: "2px solid rgb(253, 202, 64)",
                        }}
                      >
                        <StormRounded
                          sx={{
                            fontSize: 32,
                            margin: 5,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(253, 202, 64, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.1rem",
                            lineHeight: "1.1rem",
                            color: "#CACFD2",
                          }}
                        >
                          Performans
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.Performance
                            ? message.Line.Performance.toFixed(1)
                            : 0}{" "}
                          %
                        </Typography>
                      </Box>
                    </Box>
                  </div>
                  <Productivity
                    productivityValue={productivityDatas?.OEE}
                    onDialogOpen={() => setProductivityInfoDialogState(true)}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
            alignItems: "center",
          }}
        >
          <div
            className="card"
            style={{
              position: "relative",
              paddingTop: 1,
              paddingRight: 4,
              paddingBottom: 4,
              paddingLeft: 4,
              marginTop: 20,
              borderTop: "1px solid rgba(255,255,255,0.75)",
              borderBottom: "1px solid rgba(255,255,255,0.75)",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                py: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "1.2rem !important",
                  color: "#FBFCFC !important",
                }}
              >
                Üretim Akış Durumu
              </Typography>
              <Box
                sx={{
                  position: { xs: "relative", sm: "absolute" },
                  top: 3,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    paddingTop: 8,
                    paddingRight: 14,
                    paddingBottom: 8,
                    paddingLeft: 14,
                    margin: 5,
                    borderBottom: `2px solid rgb(255, 255, 255)`,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "0.9rem !important",
                      color: "rgb(236, 240, 241) !important",
                    }}
                  >
                    {100} adet / dk
                  </Typography>
                </div>
              </Box>
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              alignItems="center"
            >
              <div
                className="card"
                style={{
                  padding: 10,
                  margin: 5,
                  minWidth: 160,
                  minHeight: 65,
                  borderBottom: "2px solid rgb(4, 158, 230)",
                }}
              >
                <DebugModeInfo label="LineInBottlePieces" top={-10} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.1rem",
                      lineHeight: "1.1rem",
                      color: "#CACFD2",
                      pt: 0.2,
                      pb: 2,
                    }}
                  >
                    Giren Şişe
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 42,
                      height: 42,
                      px: 0,
                      mr: 1.5,
                      borderRadius: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(4, 158, 230, 0.21)",
                      color: "rgba(4, 158, 230)",
                      borderBottom: "2px solid rgb(4, 158, 230)",
                    }}
                  >
                    <svg
                      style={{
                        margin: 7,
                        filter:
                          "drop-shadow(1px 3px 5px rgba(4, 158, 230, 0.81))",
                      }}
                      fill="#1F3140"
                      height="64px"
                      width="64px"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="-26.68 -26.68 320.14 320.14"
                      enableBackground="new 0 0 266.777 266.777"
                      transform="rotate(0)"
                      stroke="#1F3140"
                      strokeWidth="0.00266777"
                    >
                      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#049ee6"
                        strokeWidth="53.355399999999996"
                      >
                        <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                      </g>
                      <g id="SVGRepo_iconCarrier">
                        <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                      </g>
                    </svg>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.2rem",
                        lineHeight: "1.2rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {message.Line.InBottlePieces}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        lineHeight: "0.75rem",
                        color: "#CACFD2",
                      }}
                    >
                      Adet
                    </Typography>
                  </Box>
                </Box>
              </div>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(155, 89, 182)",
                  }}
                >
                  <DebugModeInfo label="LineDolumBottleOutCounter" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Dolan Şişe
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          height: 42,
                          p: 0,
                          mr: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(155, 89, 182, 0.21)",
                          color: "rgba(155, 89, 182)",
                          borderBottom: "2px solid rgb(155, 89, 182)",
                        }}
                      >
                        <svg
                          style={{
                            margin: 6,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(155, 89, 182, 0.81))",
                          }}
                          fill="#9b59b6"
                          height="64px"
                          width="64px"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="-26.68 -26.68 320.14 320.14"
                          enableBackground="new 0 0 266.777 266.777"
                          transform="rotate(0)"
                          stroke="#9b59b6"
                          strokeWidth="0.00266777"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            stroke="#9b59b6"
                            strokeWidth="13.872404"
                          >
                            <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                          </g>
                          <g id="SVGRepo_iconCarrier">
                            <path d="m179.638,90.081c-7.693-6.157-11.276-15.514-11.276-29.446v-17.635h11.527v-43h-93v43h11.527v17.635c0,14.874-3.073,23.681-10.276,29.446-19.44,15.559-27.369,31.983-27.369,56.692v100.088c0,10.981 8.934,19.916 19.915,19.916h105.405c10.981,0 19.916-8.935 19.916-19.916v-100.088c0-22.616-9.611-43.28-26.369-56.692z"></path>{" "}
                          </g>
                        </svg>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.DolumBottleOutCounter}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(255, 76, 81)",
                  }}
                >
                  <DebugModeInfo label="LineUnfillableBottlePieces" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Dolum Fire
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgba(255, 76, 81)",
                          borderBottom: "2px solid rgb(255, 76, 81)",
                        }}
                      >
                        <CloseRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.UnfillableBottlePieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo
                    label="LineVerifiedBottleTotalPieces"
                    top={-10}
                  />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Etiketli Ürün
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          borderRadius: 2,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgba(2, 224, 164)",
                          borderBottom: "2px solid rgba(2, 224, 164)",
                        }}
                      >
                        <QrCodeRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.VerifiedBottleTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(255, 76, 81)",
                  }}
                >
                  <DebugModeInfo
                    label="LineRejectBottleTotalPieces"
                    top={-10}
                  />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Etiket Fire
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgb(255, 76, 81)",
                          borderBottom: "2px solid rgb(255, 76, 81)",
                        }}
                      >
                        <CloseRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.RejectBottleTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo label="LineVerifiedBoxTotalPieces" top={-10} />

                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Kutulanan Ürün
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(2, 224, 164, 0.21)",
                          color: "rgb(2, 224, 164)",
                          borderBottom: "2px solid rgb(2, 224, 164)",
                        }}
                      >
                        <svg
                          style={{
                            margin: 5,
                            filter:
                              "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                          }}
                          width="64px"
                          height="64px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          stroke="#2, 224, 164"
                        >
                          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <path
                              d="M4 15.8294V15.75V8C4 7.69114 4.16659 7.40629 4.43579 7.25487L4.45131 7.24614L11.6182 3.21475L11.6727 3.18411C11.8759 3.06979 12.1241 3.06979 12.3273 3.18411L19.6105 7.28092C19.8511 7.41625 20 7.67083 20 7.94687V8V15.75V15.8294C20 16.1119 19.8506 16.3733 19.6073 16.5167L12.379 20.7766C12.1451 20.9144 11.8549 20.9144 11.621 20.7766L4.39267 16.5167C4.14935 16.3733 4 16.1119 4 15.8294Z"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M12 21V12"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M12 12L4 7.5"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                            <path
                              d="M20 7.5L12 12"
                              stroke="#02e0a4"
                              strokeWidth="2"
                            ></path>
                          </g>
                        </svg>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.VerifiedBoxTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(243, 156, 18)",
                  }}
                >
                  <DebugModeInfo label="LineRecycleBottles" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Kutulama Tekrar
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(243, 156, 18, 0.21)",
                          color: "rgb(243, 156, 18)",
                          borderBottom: "2px solid rgb(243, 156, 18)",
                        }}
                      >
                        <AutoModeRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(243, 156, 18, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.RecycleBottles}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 3,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(2, 224, 164)",
                  }}
                >
                  <DebugModeInfo
                    label="LineVerifiedBottleTotalPieces"
                    top={-10}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 500,
                        fontSize: "1.1rem",
                        lineHeight: "1.1rem",
                        color: "#CACFD2",
                        pt: 0.2,
                        pb: 2,
                      }}
                    >
                      Çıkan Ürün
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: 42,
                        p: 0,
                        height: 42,
                        mr: 1.5,
                        display: "flex",
                        borderRadius: 2,
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "rgba(2, 224, 164, 0.21)",
                        color: "rgb(2, 224, 164)",
                        borderBottom: "2px solid rgb(2, 224, 164)",
                      }}
                    >
                      <DoneOutlineRounded
                        sx={{
                          filter:
                            "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        flexDirection: "column",
                        alignItems: "flex-end",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 700,
                          fontSize: "1.2rem",
                          lineHeight: "1.2rem",
                          color: "#FBFCFC",
                        }}
                      >
                        {message.Line.VerifiedBottleTotalPieces}
                      </Typography>
                      <Typography
                        sx={{
                          mt: 1,
                          fontWeight: 500,
                          fontSize: "0.75rem",
                          lineHeight: "0.75rem",
                          color: "#CACFD2",
                        }}
                      >
                        Adet
                      </Typography>
                    </Box>
                  </Box>
                </div>
                <div
                  className="card"
                  style={{
                    padding: 10,
                    margin: 5,
                    minWidth: 160,
                    minHeight: 65,
                    borderBottom: "2px solid rgb(255, 76, 81)",
                  }}
                >
                  <DebugModeInfo label="LineRejectTotalPieces" top={-10} />
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 500,
                          fontSize: "1.1rem",
                          lineHeight: "1.1rem",
                          color: "#CACFD2",
                          pt: 0.2,
                          pb: 2,
                        }}
                      >
                        Toplam Fire
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: 42,
                          p: 0,
                          height: 42,
                          mr: 1.5,
                          display: "flex",
                          borderRadius: 2,
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "rgba(255, 76, 81, 0.21)",
                          color: "rgb(255, 76, 81)",
                          borderBottom: "2px solid rgb(255, 76, 81)",
                        }}
                      >
                        <CloseRounded
                          sx={{
                            filter:
                              "drop-shadow(1px 3px 5px rgba(255, 76, 81, 0.81))",
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: 700,
                            fontSize: "1.2rem",
                            lineHeight: "1.2rem",
                            color: "#FBFCFC",
                          }}
                        >
                          {message.Line.RejectTotalPieces}
                        </Typography>
                        <Typography
                          sx={{
                            mt: 1,
                            fontWeight: 500,
                            fontSize: "0.75rem",
                            lineHeight: "0.75rem",
                            color: "#CACFD2",
                          }}
                        >
                          Adet
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </div>
              </Box>
              <div
                className="card"
                style={{
                  padding: 10,
                  margin: 3,
                  minWidth: 160,
                  minHeight: 65,
                  borderBottom: "2px solid rgb(2, 224, 164)",
                }}
              >
                <DebugModeInfo label="LinePackageOutPieces" top={-10} />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "1.1rem",
                      lineHeight: "1.1rem",
                      color: "#CACFD2",
                      pt: 0.2,
                      pb: 2,
                    }}
                  >
                    Çıkan Koli
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: 42,
                      p: 0,
                      height: 42,
                      mr: 1.5,
                      display: "flex",
                      borderRadius: 2,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "rgba(2, 224, 164, 0.21)",
                      color: "rgb(2, 224, 164)",
                      borderBottom: "2px solid rgb(2, 224, 164)",
                    }}
                  >
                    <Inventory2Rounded
                      sx={{
                        filter:
                          "drop-shadow(1px 3px 5px rgba(2, 224, 164, 0.81))",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: "1.2rem",
                        lineHeight: "1.2rem",
                        color: "#FBFCFC",
                      }}
                    >
                      {message.Line.PackageOutPieces}
                    </Typography>
                    <Typography
                      sx={{
                        mt: 1,
                        fontWeight: 500,
                        fontSize: "0.75rem",
                        lineHeight: "0.75rem",
                        color: "#CACFD2",
                      }}
                    >
                      Adet
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Box>
          </div>
        </Box>
        <SelectedMachineInfoDialog
          selectedMachine={selectedMachine}
          setSelectedMachine={setSelectedMachine}
        />
        <ProductivityInfoDialog
          productivityInfoData={productivityDatas}
          dialogState={productivityInfoDialogState}
          setDialogState={setProductivityInfoDialogState}
        />
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          mt: 3,
          minHeight: 300,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: { xs: "center", lg: "space-between" },
            gap: 1.5,
          }}
        >
          {message.Machines.map((machine, index) => (
            <MachineLight
              final={true}
              title={machine.name}
              machine={machine}
              tableInfo={
                machine.name === "Dolum"
                  ? message.Line.DolumTurnTableFull
                  : false
              }
              setSelectedMachine={setSelectedMachine}
            />
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default PageShiftDetailsPage;
