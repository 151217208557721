// ** Icons Imports
import {
  CloseRounded,
  FlagTwoTone,
  VisibilityRounded,
} from "@mui/icons-material";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import DataGridSpeacial from "./DataGridSpecial";
import { convertMsToTime } from "../App";
import { GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useMemo, useState } from "react";
import MachineInfoDialog from "./MachineInfoDialog";
import dateFormat from "dateformat";
import {
  Besleme,
  Dolum,
  Etiket,
  Kolileme,
  Kutulama,
  Machines,
  Message,
} from "../message";

// ** Types
type ShiftHourInfoDialogProps = {
  shiftHourData: Message;
  dialogState: boolean;
  setDialogState: (value: React.SetStateAction<boolean>) => void;
};

const ShiftHourInfoDialog = (props: ShiftHourInfoDialogProps) => {
  // ** Columns
  const customizedColumns = useMemo<GridColDef[]>(
    () => [
      {
        flex: 0.07,
        minWidth: 172,
        field: "name",
        headerName: "Makine Adı",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {row.name}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        minWidth: 128,
        field: "DownTime",
        headerName: "Duruş Süresi",
        align: "center",
        headerAlign: "center",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          console.log(row);

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {convertMsToTime(row.DownTime)}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        minWidth: 128,
        field: "RunTime",
        headerName: "Çalışma Süresi",
        align: "center",
        headerAlign: "center",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;

          return (
            <Typography
              noWrap
              sx={{ fontSize: 13.5, color: "#FCFBFB", fontWeight: 500 }}
            >
              {convertMsToTime(row.RunTime)}
            </Typography>
          );
        },
      },
      {
        flex: 0.07,
        minWidth: 128,
        field: "Efficiency",
        headerName: "Verim",
        align: "center",
        headerAlign: "center",
        disableColumnMenu: false,
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          return (
            <Box
              sx={{
                width: "100%",
                px: 2,
              }}
            >
              <Typography
                noWrap
                align="center"
                sx={{ fontSize: 12.5, color: "#FCFBFB", fontWeight: 800 }}
              >
                {row.Efficiency && row.Efficiency.toFixed(1)}
              </Typography>
              <Slider
                sx={{
                  background: "#FF4C51",
                  height: 5,
                  p: 0,
                  "& .MuiSlider-track": {
                    border: "none",
                    background: "#02E0A4",
                  },
                  "& .MuiSlider-thumb": {
                    height: 9,
                    width: 9,
                    borderRadius: 3,
                    background: "#02E0A4",
                    border: `1px solid #02E0A4`,
                    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                      boxShadow: "inherit",
                    },
                    "&:before": {
                      display: "none",
                    },
                  },
                  "& .MuiSlider-mark": {
                    background: "none",
                  },
                }}
                disabled
                aria-label="Efficiency"
                defaultValue={row.Efficiency}
                valueLabelDisplay="auto"
                step={10}
                marks
                min={0}
                max={100}
              />
            </Box>
          );
        },
      },
      {
        flex: 0.03,
        minWidth: 34,
        field: "on_detail",
        headerName: "",
        align: "right",
        headerAlign: "right",
        renderCell: (params: GridRenderCellParams) => {
          const { row } = params;
          return (
            <Tooltip title="İncele" placement="left">
              <IconButton
                sx={{
                  width: 32,
                  height: 32,
                  marginRight: 0.5,
                  borderRadius: 1,
                  backgroundColor: "rgba(255, 255, 255, 0.13)",
                  ":hover": {
                    backgroundColor: "rgba(255, 255, 255, 0.21)",
                  },
                  border: "1px solid rgba(255,255,255,0.24)",
                }}
                onClick={() => {
                  onOpenSensorDetail(row);
                }}
              >
                <VisibilityRounded
                  fontSize="small"
                  sx={{
                    color: "#f0f3f4 !important",
                  }}
                />
              </IconButton>
            </Tooltip>
          );
        },
      },
    ],
    []
  );

  // ** Props
  const { shiftHourData, dialogState, setDialogState } = props;

  // ** States
  const [rows, setRows] = useState<
    Besleme | Dolum | Etiket | Kutulama | Kolileme[]
  >([]);
  const [rowCountState, setRowCountState] = useState(0);

  const [selectedMachineData, setSelectedMachineData] = useState<Machines[0]>({
    id: 0,
    name: "none",
    Green: false,
    Red: false,
    Yellow: false,
    RunTime: 0,
    DownTime: 0,
    WarningTime: 0,
    IdleTime: 0,
    Efficiency: 0,
    Availability: 0,
    Performance: 0,
    Quality: 0,
    Sensors: [],
    Errors: [],
  });

  const [machineInfoDialog, setMachineInfoDialog] = useState<boolean>(false);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  // ** Functions
  const onClose = () => {
    setDialogState(false);
  };

  const onDetail = () => {};

  const onOpenSensorDetail = (row: Machines[0]) => {
    setSelectedMachineData(row);
    setMachineInfoDialog(true);
  };

  useEffect(() => {
    setRows(shiftHourData.Machines);
    setRowCountState(shiftHourData.Machines.length);
  }, [shiftHourData]);

  return (
    <Dialog
      fullWidth
      open={dialogState}
      maxWidth="md"
      scroll="body"
      onClose={onClose}
      sx={{
        ".MuiDialog-paper": {
          boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
          backgroundColor: "rgba(255, 255, 255, 0.13) !important",
        },
      }}
    >
      <DialogTitle sx={{ p: 0, mb: 5 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            backgroundColor: "rgba(255, 255, 255, 0.09)",
            p: 1,
          }}
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              backgroundColor: "rgba(255, 255, 255, 0.09)",
              borderBottom: "3px solid rgba(255, 255, 255, 0.72)",
              p: 1,
              borderRadius: 1,
            }}
          >
            <FlagTwoTone
              sx={{
                color: "#f0f3f4",
              }}
            />
          </Box>
          <Typography
            variant="subtitle1"
            sx={{
              fontSize: "1.3rem",
              color: "#f0f3f4",
              fontWeight: 500,
            }}
          >
            Hat Bilgileri
          </Typography>
          <IconButton
            sx={{
              color: "#f0f3f4",
            }}
            onClick={onClose}
            size="small"
            aria-label="settings"
          >
            <CloseRounded />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3.5 }}>
        <Box
          sx={{
            width: "100%",
            px: 2,
            pb: 3,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              px: 1.35,
              py: 0.45,
              backgroundImage:
                "linear-gradient(140deg, hsl(240deg 5% 12%) 0%, hsl(240deg 11% 12%) 38%, hsl(240deg 18% 7%) 100%)",
              borderRadius: 12,
            }}
          >
            <Typography
              noWrap
              align="center"
              sx={{ fontSize: 13, color: "#ECF0F1", fontWeight: 600 }}
            >
              {`${dateFormat(
                Number(shiftHourData.Time) * 1000,
                "dd.mm.yyyy, dddd"
              )} tarihindeki ${shiftHourData.Text} vardiyası`}
            </Typography>
          </Box>
        </Box>
        <DataGridSpeacial
          columns={customizedColumns}
          rows={rows}
          onDetail={onDetail}
          rowCountState={rowCountState}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
        />
      </DialogContent>
      <MachineInfoDialog
        machineData={selectedMachineData}
        machineInfoDialog={machineInfoDialog}
        setMachineInfoDialog={setMachineInfoDialog}
      />
    </Dialog>
  );
};

export default ShiftHourInfoDialog;
